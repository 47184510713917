.allOppWrapper {
  display: flex;
  flex-direction: column;
  height: 90%;
  width: 100%;
}
.allOpps {
  display: flex;
  height: 30%;
  padding: 1rem;
}
.oppCard {
  width: 33.33%;
  margin-right: 2rem;
}
.allOppTypes {
  display: grid;
  height: 70%;
  grid-template-columns: repeat(4, 1fr);
  row-gap: 3vh;
  column-gap: 6vh;
  width: 100%;
  padding: 1rem;
}

.eachType {
  padding: 1rem;
  display: flex;
  box-sizing: border-box;
  background: #ffffff;
  box-shadow: 0px 0px 20px rgba(28, 32, 98, 0.08);
  border-radius: 6px;
}

.oppDetailsCard {
  width: 70%;
  display: flex;
  flex-direction: column;
  justify-content: space-around;
}
.oppCount {
  font-weight: 700;
  font-size: 2.5rem;
  padding: 1rem;
}
.oppName {
  font-size: 2rem;
  font-weight: 500;
  padding: 1rem;
}
.oppImageWrapper {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 30%;
}
.oppImg {
  width: 90%;
}


@media screen and (max-width: 800px) {
  .allOppTypes {
    display: grid;
    height: auto;
    grid-template-columns: repeat(2, 1fr);
    row-gap: 3vh;
    column-gap: 2vh;
    width: 95%;
    margin: auto;
    padding: 0.5rem;
  }
  .allOpps {
    display: flex;
    height: auto;
    padding: 1rem;
    width:100%;
  }
  .oppCard {
    width: 45%;
  }
  .eachType {
    flex-direction: column-reverse;
    justify-content: space-between;
  }
  .oppDetailsCard {
    width: 100%;
    display: flex;
    flex-direction: column;
    justify-content: space-around;
  }
  .oppImageWrapper {
    width: 100%;
  }
  .oppImg {
    width: 40%;
  }
  .oppCount {
    text-align: center;
    font-weight: 700;
    font-size: 2rem;
    padding: 1rem;
  }
  .oppName {
    text-align: center;
    font-size: 1.4rem;
    font-weight: 500;
  }
  
}
