.aboutusInfoWrapper {
  display: flex;
  flex-direction: column;
  position: relative;
  min-height: 100vh;
  display: flex;
  align-items: center;
  background: #0c0f47;
  color: white;
  background-image: url("../../../assets/footerBg.png");
  background-repeat: no-repeat;
  background-size: cover;
}
.aboutUsContent {
  display: flex;
}
.bgCircleLeft {
  position: absolute;
  bottom: 0;
  left: 0;
}
.bgCircleRight {
  position: absolute;
  top: -200px;
  right: -200px;
}
.aboutUsLeft {
  width: 30%;
}
.aboutUsImg {
  width: 100%;
}
.aboutUsHeading {
  font-weight: 800;
  font-size: 10rem;
  padding: 10rem 20rem;
}
.aboutUsInfo {
  font-weight: 400;
  font-size: 2.4rem;
}
.aboutUsRight {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 45%;
}

@media screen and (max-width: 768px) {
  .aboutusInfoWrapper {
    flex-direction: column;
  }
  .aboutUsHeading {
    font-weight: 700;
    font-size: 3.2rem;
    text-align: center;
    padding: 5rem 0rem;
  }
  .aboutUsLeft {
    width: 70%;
    padding: 1rem;
  }
  .aboutUsContent {
    display: flex;
    flex-direction: column;
    width: 100%;
    padding-top: 2rem;
  }
  .aboutUsRight {
    width: 90%;
    margin: auto;
  }
  .aboutUsInfo {
    font-weight: 400;
    font-size: 2rem;
    text-align: center;
    padding: 2rem;
  }
}
