.associationWrapper {
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  background: #0c0f47;
  color: white;
  background-image: url("../../../assets/footerBg.png");
  background-repeat: no-repeat;
  background-size: cover;
}
.associationHeading1 {

  font-size: 6.4rem;
  font-weight: 700;
  text-align: center;
  padding: 4rem;
}
.associationHeadingSpan {

  font-size: 6.4rem;
  font-weight: 700;
  text-align: center;
  padding-left: 1rem;
  padding-right: 1rem;
}
.associationHeading2 {
  font-weight: 300;
  font-size: 2.4rem;
  text-align: center;
  width: 37.5%;
  padding: 2rem;
}

.hiringCompaniesDesktop {
  display: flex;
  min-width: 80%;
  min-height: 60%;
}
.companyLogosDesktop {
  display: flex;
  flex-direction: column;
  justify-content: space-evenly;
  width: 20%;
}
.companyDesktop, .companyMobile {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 70%;
  height: 10rem;
  margin: 2rem;
  padding: 2rem;
  box-sizing: border-box;
  background: white;
  color: black;
  font-size: 2.5rem;
  text-align: center;
  font-weight: 800;
  border-radius: 8px;
  animation: smoothZoom 5s infinite ease-in-out;
}

.zoomdiff{
  animation: smoothContractExpand 5s infinite ease-in-out;
}
.companyDesktop img {
  width: 80%;
}

.hiringCompaniesMobile {
  display: none;
}

@keyframes smoothZoom {
  0% {
    transform: scale(1);
  }
  25% {
    transform: scale(1.2);
  }
  50% {
    transform: scale(1);
  }
  75% {
    transform: scale(0.8);
  }
  100% {
    transform: scale(1);
  }
}

@keyframes smoothContractExpand {
  0% {
    transform: scale(1);
  }
  25% {
    transform: scale(0.8);
  }
  50% {
    transform: scale(1);
  }
  75% {
    transform: scale(1.2);
  }
  100% {
    transform: scale(1);
  }
}

@media screen and (max-width: 768px) {
  .associationHeading1 {
    font-size: 3rem;
  }

  .associationHeadingSpan {
    font-size: 3rem;
  }

  .associationHeading2 {
    font-weight: 300;
    font-size: 2rem;
    text-align: center;
    width: 70%;
    padding: 2rem;
  }

  .companyDesktop img {
    width: 60%;
  }

  .hiringCompaniesDesktop {
    /* display: none; */
  }

  .hiringCompaniesMobile {
    display: flex;
    flex-direction: column;

  }

  .companyDesktop, .companyMobile {
    height: 7rem;
    margin: 2rem;
    padding: 2rem;
    box-sizing: border-box;
    background: white;
    color: black;
    font-size: 1.2rem;
  }
}
