.steps-wrapper {
    display: flex;
    align-items: center;
    margin: 2rem 0;
    justify-content: center;
    gap: 1rem;
  }
  
  .step-number {
    width: 32px;
    height: 32px;
    border-radius: 50%;
    border: 2px solid #010334;
    display: flex;
    align-items: center;
    justify-content: center;
    margin-right: 0.5rem;
    font-weight: 500;
  }
  
  .step-text {
    margin-right: 2rem;
    font-weight: 500;
  }
  
  .step-active {
    background-color: #010334;
    color: white;
  }
  
  .step-inactive {
    background-color: white;
    color: #010334;
  }
  
  .navigation-buttons {
    display: flex;
    justify-content: space-between;
    gap: 1rem;
    margin-top: 2rem;
    padding: 0 1rem;
  }
  
  .navigation-buttons button {
    min-width: 120px;
  }
  
  @media (max-width: 768px) {
    .navigation-buttons {
      flex-direction: column;
    }
    
    .navigation-buttons button {
      width: 100%;
    }
    
    .steps-wrapper {
      flex-wrap: wrap;
    }
  }
  .studentFormWrapper {
    display: flex;
    flex-direction: column;
    align-items: center;
    width: 90%;
    margin: 0 auto;
  }
  
  .inputForm {
    width: 100%;
  }
  
  /* Make Interested Regions full width */
  .regionFinalWrapper {
    width: 100%;
  }
  
  .btn-modal {
    width: 100%;
  }
  