/* Add this CSS to your existing CSS file or create a new one for the modal styles */

.modal {
    display: none;
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background: rgba(0, 0, 0, 0.5);
    justify-content: center;
    align-items: center;
    z-index: 1;
  }
  
  .modal.open {
    display: flex;
  }
  
  .modal-content-profile {
      display: flex;
      flex-direction: column;
      justify-content: space-between;
      align-items: center;
      position: fixed;
      overflow-y: scroll;
      top: 50%;
      left: 50%;
      transform: translate(-50%, -50%);
      line-height: 1.4;
      background: #ffffff;
      padding: 14px 28px;
      border-radius: 3px;
      width: 40%;
      max-height: 80%;
      padding: 3rem;
      width: 60%;
      min-width: 50%;
      box-shadow: 0 2px 10px rgba(0, 0, 0, 0.1);
      height: 40%;
  }
  
  .close-button {
    position: absolute;
    top: 10px;
    right: 10px;
    background: none;
    border: none;
    cursor: pointer;
  }
  
  .bold-text {
    font-size: 3.5rem;
    font-weight: bold;
    margin-bottom: 5px;
  }

  .about-description {
    margin-bottom: 50px; /* Adjust the value as needed */
    font-size: 2rem;
  }

  .aboutTextArea {
    width: 100%;
    height:18rem;
    padding: 1rem;
    width: 80%;
    margin-bottom: 2rem;
    border: 1px solid #ccc;
    border-radius: 1rem;
    color: black;
  }

  .aboutTextArea::placeholder{
    color: grey;
    border-radius: 10px;
  }
  
  