.mentorsWrapper{
display: flex;
  flex-direction: column;
  justify-content: center;
  background: #ffffff;
  border-top: 3px solid #dfa114;
  border-radius: 4px;
  padding: 1.5rem;
}

.mentorsHeading{
  font-weight: 700;
  padding: 1.5rem;
  font-size: 3rem;
}

.mentorsDescription{
  font-weight: 700;
  padding: 1rem;
  font-size: 2rem;
}

.allMentors{
  width:98%;
  overflow-y: auto;
}

@media screen and (max-width: 800px) {
  .opportunityDetailsRight{
      display: flex;
      flex-direction: column;
      min-width:80%;
  }
}