.carouselWrapper {
  height: 100%;
  min-width: 400px;
}
.react-multi-carousel-list {
  height: 100%;
}
.carouselItem {
  display: flex;
  flex-direction: column;
  transition: all linear 0.4s;
  margin: auto;
}
.slick-list,
.slick-slider,
.slick-track {
  padding: 3rem;
  border-radius: 8px;
}
.center .slick-center .carouselItem {
  border-radius: 8px;
  transform: scale(1.2);
}

.slick-dots {
  font-size: 18px; /* increase dot size */
}

.slick-dots li button:before {
  color: white;
}

.slick-dots li.slick-active button:before {
  color: white !important;
}

@media only screen and (max-width: 768px) {
  .slick-list,
  .slick-slider,
  .slick-track {
    padding: 0rem;
    border-radius: 8px;
  }
}
