.examWrapper {
  width: 100%;
}

.examDetailWrapper {
  width: 100%;
}

.form-control-exams {
  font-size: 2rem;
  padding: 1rem 0rem 1rem 0.4rem;
  border-radius: 8px;
  width: 98%;
  border: 1px solid #010334;
  background-color: rgb(255, 255, 255, 0.4) !important;
  line-height: 4.44%;
}

.examName {
  width: 100%;
  margin-bottom: 2rem;
}

.examDetails {
  display: flex;
  justify-content: space-between;
}

.examPercent {
  display: flex;
  font-size: 2rem;
  padding: 1rem;
  border-radius: 8px;
  width: 80%;
  border: 1px solid #010334;
  background-color: rgb(255, 255, 255, 0.4) !important;
}

.examDate {
  width: 35%;
  padding: 1rem;
  border-radius: 8px;
  border: 1px solid #010334;
  background-color: rgb(255, 255, 255, 0.4) !important;
}

.scoreInput {
  width: 100%;
  text-align: center;
  border: none;
}
.scoreInput:focus {
  outline: none;
}
.examDetails {
  border: none;
}
.examScore {
  border: none;
  border-right: 1px solid #010334 !important;
  padding: 0.5rem;
  width: 50%;
}
.datePickerReact {
  width: 100%;
  border: none;
}
.datePickerReact:focus {
  border: none;
  outline: none;
}
.outOfTeller {
  width: 50%;
  font-weight: 700;
  display: flex;
  text-align: center;
  justify-content: center;
  align-items: center;
  padding: 0.5rem;
}

.exam-dropdown-menu {
  max-height: 30vh;
  overflow-y: scroll;
  overflow-x: hidden;
  flex-direction: column;
}
.exam-dropdown-menu::-webkit-scrollbar {
  display: none;
}
.examDelete {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 10%;
  padding: 0.5rem;
}
