.input-field-container {
  margin-bottom: 16px;
}

.input-label {
  display: block;
  margin-bottom: 8px;
  font-weight: bold;
}

.input-field::placeholder {
  color: grey; /* Placeholder text color */
}

/* Optional: Additional styles to ensure proper display */
.input-field {
  width: 100%;
  border-radius: 4px;
  padding: 10px;
  border: 1px solid #ccc;
  font-size: 16px;
}
