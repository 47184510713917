@import url("./css/cards.css");
@import url("./css/flexUtils.css");
@import url("./css/sideNav.css");

.profileWrapper{
  /* overflow-x: hidden; */
}
/* Set up the grid container */
.container {
  display: grid;
  grid-template-columns: 20% 60% 20%;
  /* min-height: 100vh; */
}

/* profile */
.main-content {
  overflow-y: auto;
  padding: 1rem;
}

.further-details {
  position: sticky;
  bottom: 0;
}
.card {
  position: relative;
  /* Add other styles for the card as needed */
}

.edit-button {
  position: absolute;
  top: 10px; /* Adjust the top position as needed */
  right: 10px; /* Adjust the right position as needed */
  background-color: white; /* Background color */
  color: #fff; /* Text color */
  border: none;
  padding: 5px 10px;
  cursor: pointer;
}

.sidebarProfile{
  position: -webkit-sticky; /* For Safari */
  min-width: 20%;
  /* height: 100vh; */
  position: sticky;
  top: 0;
  overflow-y: auto;         /* Allows scrolling within the sidebar if needed */
}

@media screen and (max-width: 768px) {
  .container {
    grid-template-columns: 100%; /* Single column for mobile screens */
    display: flex;
    flex-direction: column-reverse;
    align-items: stretch ; /* Stretch items to fill the width */
  }
  .card {
    padding: 1rem; 
    margin: 0.5rem 0;
  }
  
  .hero-avatar {
    position: absolute;
  top: 250px; /* Adjust the value as needed */
  left: 0;
  }
  .hero-card {
    flex: 0 0 90%; /* Adjust the width of the hero card */
    margin: 0.5rem 0;
    padding: 1rem;
  }
  .hero-card {
    display: flex;
    flex-direction: column; /* Stack items vertically */
    align-items: center; /* Center items horizontally within .hero-card */
    overflow : hidden ;
    /* Add other styles as needed */
  }
  .App {
    min-height: 0vh;
  }
  .side-navbar {
    display: none;
    flex-direction: column;
    justify-content: space-between;
    height: 100%; /* Ensure the side navbar takes the full height */
  }
  .menu-list {
    list-style: none;
    padding: 0;
    margin: 0;
    display: flex;
    flex-wrap: wrap; /* Allow items to wrap to the next row */
    justify-content: space-between; /* Ensure equal space between items */
  }
}
@media screen and (max-width: 280px) {
  .container {
    grid-template-columns: 100%; /* Single column for mobile screens */
    display: flex;
    flex-direction: column-reverse; 
    align-items: stretch ; /* Stretch items to fill the width */
  }
  .card {
    padding: 1rem; 
    margin: 0.5rem 0;
  }
  
  .hero-avatar {
    position: absolute;
  top: 250px; /* Adjust the value as needed */
  left: 0;
  }
  .hero-card {
    flex: 0 0 90%; /* Adjust the width of the hero card */
    margin: 0.5rem 0;
    padding: 1rem;
  }
  .hero-card {
    display: flex;
    flex-direction: column; /* Stack items vertically */
    align-items: center; /* Center items horizontally within .hero-card */
    overflow : hidden ;
    /* Add other styles as needed */
  }
  .App {
    min-height: 0vh;
  }
  .side-navbar {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    height: 100%; /* Ensure the side navbar takes the full height */
  }
  .menu-list {
    list-style: none;
    padding: 0;
    margin: 0;
    display: flex;
    flex-wrap: wrap; /* Allow items to wrap to the next row */
    justify-content: space-between; /* Ensure equal space between items */
  }
}
