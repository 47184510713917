.resetPassWrapper {
  display: flex;
}

.resetFormWrapper {
  font-family: "Rogan";
  width: 51%;
  display: flex;
  flex-direction: column;
  height: 100vh;
  justify-content: space-evenly;
  align-items: center;
}

.midWrapperReset {
  display: flex;
  flex-direction: column;
  justify-content: space-evenly;
  align-items: center;
  height: 50%;
  width: 50%;
}

.resetButton {
  display: flex;
  justify-content: center;
  font-size: 1.2rem;
  font-size: 1rem;
  background-color: #dfa114;
  width: 100%;
}
