.form-container-contact-us {
  margin: auto;
  width: 80%;
  min-height: 100vh;
  padding-top: 12rem;
}

.form-title-contact-us {
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  color: #dfa114;
  font-size: 5rem;
  font-weight: 800;
  margin-bottom: 3rem;
}

.form-description {
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  font-size: 4rem;
  font-weight: 700;
  margin-bottom: 3rem;
}

.contact-form {
  display: flex;
  flex-direction: column;
}

.form-group {
  margin-bottom: 2rem;
}

.form-label {
  font-weight: bold;
}

.form-input,
.form-textarea {
  width: 100%;
  padding: 10px;
  font-size: 16px;
  margin-top: 5px;
  margin-bottom: 5px;
  border-radius: 10px;
  border: 1px solid #d9d9d9;
}

.form-textarea {
  height: 100px;
}

.form-submit-button {
  display: flex;
  justify-content: center;
  align-items: center;
  margin: auto;
  width: 30%;
  padding: 10px 20px;
  font-weight: 600;
  font-size: 2rem;
  border-radius: 10px;
  color: black;
  background-color: #dfa114;
  border: none;
  cursor: pointer;
}

.form-footer {
  display: flex;
  justify-content: center;
  align-items: center;
  margin: auto;
  width: 80%;
  padding: 10px 20px;
  margin-top: 20px;
  font-size: 1.4rem;
  color: grey;
}

@media screen and (max-width: 768px) {
  .form-container-contact-us {
    padding-top: 4rem;
  }

  .form-title-contact-us {
    font-size: 3.5rem;
    margin-bottom: 1.5rem;
  }
  .form-description {
    font-size: 2rem;
    text-align: center;
  }
  .form-footer {
    text-align: center;
  }
}
.success-message {
  background-color: #d4edda;
  color: #155724;
  padding: 1rem;
  margin-bottom: 1rem;
  border-radius: 4px;
  text-align: center;
}

.error-message {
  background-color: #f8d7da;
  color: #721c24;
  padding: 1rem;
  margin-bottom: 1rem;
  border-radius: 4px;
  text-align: center;
}

.form-submit-button:disabled {
  opacity: 0.7;
  cursor: not-allowed;
}
