.loginWrapper {
  display: flex;
  flex-direction: row;
  width: 100%;
  max-height: 100vh;
}
.messageLogin {
  width: 49%;
}

.userLoginForm {
  font-family: "Rogan";
  width: 51%;
  display: flex;
  flex-direction: column;
  height: 100vh;
  justify-content: space-evenly;
  align-items: center;
}

.midWrapperLogin {
  display: flex;
  flex-direction: column;
  justify-content: space-evenly;
  height: 90%;
  width: 55%;
}

.loginMessage {
  display: flex;
  justify-content: center;
  color: #010334;
  font-size: 4rem;
  font-weight: 700;
  text-align: center;
}

.welcomeMessage {
  display: flex;
  justify-content: center;
  align-items: center;
}
.welcomeMessageSpan {
  display: flex;
  justify-content: center;
  padding-right: 0.5rem;
}

.nameWrap {
  width: 100%;
}

.changeCol {
  color: #dfa114;
}

.passwordWrap {
  display: flex;
  border: 1px solid #010334;
  justify-content: space-between;
  border-radius: 8px;
  padding: 0.8rem;
  margin-bottom: 2rem;
  width: 94%;
}

.inputPassword {
  border: 0px solid;
  outline: none;
}
.showPassword {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 10%;
}
.showPasswordBtn {
  display: flex;
  justify-content: center;
  align-items: center;
  border: none;
  background-color: #fff;
  margin-top: 0;
  width: 50%;
}

.eyeImg {
  width: 100%;
}

.loginOptions {
  display: flex;
  justify-content: space-between;
  width: 98%;
}

.rememberLogin {
  display: flex;
  justify-content: flex-start;
  align-items: center;
  font-size: 1.8rem;
  width: 60%;
}

.forgotPassword {
  font-size: 1.6rem;
  width: 40%;
  display: flex;
  font-weight: 500;
  justify-content: flex-end;
}

.phonenumEnter {
  width: 80%;
}

.phonenumWrapLogin {
  display: flex;
  flex-direction: column;
  width: 100%;
  justify-content: space-between;
  margin-bottom: 3rem;
  width: 97%;
}

.input {
  background: #fff;
}

.userIDLogin {
  width: 100%;
}

.userIDLoginBtn {
  width: 100%;
}

.notRegistered {
  margin: 0.5rem;
  text-align: center;
}

.notRegisteredMessage {
  width: 100%;
  justify-content: center;
  display: flex;
  padding-bottom: 1rem;
}

.notRegisteredText {
  padding-right: 0.5rem;
}

.notRegisteredTextSpan {
  text-decoration: underline;
  font-weight: 500;
}

.notRegisteredTextSpan:hover {
  cursor: pointer;
}

input.rememberCheckBox {
  transform: scale(1.5);
  margin-right: 0.5rem;
}

.googleSignUp {
  width: 100%;
}

.instructions {
  font-size: 0.75rem;
  border-radius: 0.5rem;
  background: #000;
  color: #fff;
  padding: 0.25rem;
  position: relative;
  bottom: -10px;
}

.instructions > svg {
  margin-right: 0.25rem;
}

.offscreen {
  position: absolute;
  left: -9999px;
}

.line {
  display: inline-block;
}

::-webkit-input-placeholder {
  font-family: "Rogan";
  font-style: normal;
  font-weight: 400;
  font-size: 20px;
  line-height: 24px;
}

.linkStyle1 {
  display: flex;
  justify-content: center;
  align-items: center;
  font-size: 1.8rem;
  text-decoration: none;
  color: black;
  text-decoration: underline;
}

@media only screen and (max-width: 1200px) {
  /*Tablets [601px -> 1200px]*/
}

@media only screen and (max-width: 600px) {
  /*Big smartphones [426px -> 600px]*/
}

@media only screen and (max-width: 450px) {
  .loginWrapper {
    height: 100vh;
  }
  .messageLogin {
    display: none;
  }
  .userLoginForm {
    width: 86%;
    margin: auto;
  }
  .midWrapperLogin {
    width: 100%;
    height: 80%;
  }
  .loginMessage {
    display: block;
    font-size: 3rem;
    text-align: center;
  }
  .changeCol {
    display: inline;
    font-size: 3rem;
  }
  .welcomeMessage {
    font-size: 1.8rem;
  }
  .welcomeMessageSpan {
    font-size: 1.8rem;
    font-weight: 700;
  }
  .loginOptions {
    font-size: 1.4rem;
    justify-content: space-between;
  }
  .rememberLogin {
    font-size: 1.4rem;
    width: 100%;
  }
  .forgotPassword {
    width: 100%;
  }
  .linkStyle1 {
    font-size: 1.6rem;
    font-weight: 700;
  }
}
