.memberWrapper {
  display: flex;
  flex-direction: column;
  /* justify-content: space-around; */
  height: 100%;
  padding: 1rem;
  box-shadow: 2;
}
.memberImgWrapper {
  width: 50%;
  margin: auto;
}
.memberImg {
  width: 100%;
}
.memberInformation {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  padding: 1rem;
  width: 100%;
  /* height: 100%; */
}
.memberName {
  font-size: 2.3rem;
  font-weight: 800;
  padding: 0.5rem;
}
.memberRole {
  font-size: 1.8rem;
  font-weight: 700;
  padding: 0.5rem;
}
.memberHandles {
  display: flex;
  padding: 1rem;
}
.memberHandle {
  padding: 0.5rem;
}
