.hamburger {
    cursor: pointer;
    display: none;
  }
  
  .bar {
    width: 20px;
    height: 1.5px;
    background-color: #060843;
    margin: 0.4rem;
  }
  
  .mobileMenu {
    margin-top: 1rem;
    width: 100%;
    position: absolute;
    transition: all 0.5s;
    transition-timing-function: cubic-bezier(0.215, 0.61, 0.355, 1);
    max-height: 0;
    left: -100%;
  }
  
  .isOpen {
    display: block;
    left: 0 !important;
  }
  
  .mobileMenu li {
    display: block;
    font-size: 2rem;
    padding: 30px;
    font-weight: bold;
    color: black;
    cursor: pointer;
    background-color: white;
  }
  
  .mobileMenu li:hover {
    text-decoration: underline;
    text-decoration-color: #dfa114;
  }
  
  @media screen and (max-width: 800px) {
    .hamburger {
      display: block;
    }
  }
  