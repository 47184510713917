/* Container */
.blog-container {
  max-width: 1200px;
  margin: 0 auto;
  padding: 20px;
}

/* Editor Section */
.blog-editor-section {
  margin-bottom: 40px;
}

.blog-section-title {
  font-size: 24px;
  font-weight: bold;
  color: #333;
  margin-bottom: 20px;
}

/* Form Styles */
.blog-form {
  background-color: #fff;
  padding: 24px;
  border-radius: 8px;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
}

.blog-form-fields {
  display: flex;
  flex-direction: column;
  gap: 20px;
}

.blog-form-row {
  display: grid;
  grid-template-columns: 1fr 1fr;
  gap: 20px;
}

.blog-form-group {
  display: flex;
  flex-direction: column;
  gap: 8px;
}

.blog-label {
  font-size: 14px;
  font-weight: 500;
  color: #333;
}

.blog-input {
  padding: 10px;
  border: 1px solid #ddd;
  border-radius: 4px;
  font-size: 16px;
}

.blog-textarea {
  padding: 10px;
  border: 1px solid #ddd;
  border-radius: 4px;
  font-size: 16px;
  resize: vertical;
  min-height: 80px;
}

.blog-file-input {
  padding: 10px;
  border: 1px dashed #ddd;
  border-radius: 4px;
  background-color: #f8f8f8;
}

.blog-submit-button {
  margin-top: 20px;
  padding: 12px 24px;
  background-color: #0066cc;
  color: white;
  border: none;
  border-radius: 4px;
  font-size: 16px;
  font-weight: 500;
  cursor: pointer;
  transition: background-color 0.2s;
}

.blog-submit-button:hover {
  background-color: #0052a3;
}

/* Preview Section */
.blog-preview-section {
  background-color: #f8f8f8;
  padding: 40px 20px;
  border-radius: 8px;
}

.blog-preview-container {
  max-width: 900px;
  margin: 0 auto;
  background-color: white;
  padding: 40px;
  border-radius: 8px;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
}

.blog-preview-header {
  margin-bottom: 40px;
}

.blog-preview-title {
  font-size: 36px;
  font-weight: bold;
  color: #333;
  margin-bottom: 20px;
}

.blog-separator {
  height: 1px;
  background-color: #ddd;
  margin: 20px 0;
}

.blog-preview-introduction {
  font-size: 18px;
  line-height: 1.6;
  color: #666;
}

.blog-content-section {
  display: flex;
  flex-direction: column;
  gap: 40px;
}

.blog-image-text-row {
  display: grid;
  grid-template-columns: 1fr 1fr;
  gap: 40px;
  align-items: center;
}

.blog-image-wrapper {
  width: 100%;
  height: 300px;
  border-radius: 8px;
  overflow: hidden;
}

.blog-preview-image {
  width: 100%;
  height: 100%;
  object-fit: cover;
}

.blog-image-placeholder {
  width: 100%;
  height: 100%;
  background-color: #f0f0f0;
  display: flex;
  align-items: center;
  justify-content: center;
  color: #666;
  font-size: 18px;
  border: 2px dashed #ddd;
}

.blog-text-content {
  font-size: 16px;
  line-height: 1.6;
  color: #444;
}

.blog-conclusion-section {
  font-size: 16px;
  line-height: 1.6;
  color: #444;
  padding-top: 20px;
  border-top: 1px solid #ddd;
}

.blog-placeholder-image-wrapper {
  width: 100%;
  max-height: 400px;
  margin: 20px 0;
  border-radius: 8px;
  overflow: hidden;
}

.blog-image-preview-container {
  margin-top: 10px;
  max-width: 200px;
  border-radius: 4px;
  overflow: hidden;
}

.blog-image-preview {
  width: 100%;
  height: auto;
  display: block;
}

/* Responsive Design */
@media (max-width: 768px) {
  .blog-form-row {
    grid-template-columns: 1fr;
  }

  .blog-image-text-row {
    grid-template-columns: 1fr;
  }

  .blog-image-wrapper {
    height: 200px;
  }

  .blog-preview-container {
    padding: 20px;
  }
}
