.navWrapper {
  position: sticky;
  display: flex;
  padding: 1.5rem 0;
  justify-content: space-evenly;
  align-items: center;
  color: #060843;
}

.brandGroup {
  display: flex;
  gap: 1.5rem;
  align-items: center;
}

.navLogo,
.navLogo span {
  font-size: 3.2rem;
  font-weight: 800;
  cursor: pointer;
}

.navLogo:hover {
  cursor: hand;
}

.opguruLogoImg {
  width: 80%;
}

.navLinks {
  display: flex;
  font-size: 1.8rem;
}

.navLink {
  font-size: 2.4rem;
  font-weight: 500;
  margin: 0 1rem;
  cursor: pointer;
}

.activeLink {
  text-decoration: underline;
  text-decoration-color: #dfa114;
  font-weight: 600;
}

.navButtons {
  display: flex;
  gap: 1.5rem;
}

.navButton {
  padding: 0.5rem 1rem;
  background: transparent;
  border: 1.5px solid #060843;
  border-radius: 5px;
  cursor: pointer;
  font-weight: 600;
}

.navButton:hover {
  cursor: hand;
}

.noBorder {
  border: none;
}

@media screen and (max-width: 800px) {
  .navWrapper {
    justify-content: space-between;
    padding: 1rem 2.5rem;
  }
  .navLogo,
  .navLogo span {
    font-size: 2.8rem;
    font-weight: 600;
    cursor: pointer;
  }
  .brandGroup {
    display: flex;
    gap: 1rem;
    align-items: center;
  }
  .navLinks {
    display: none;
  }
  .navButtons {
    display: flex;
    gap: 1rem;
  }
  .navButton {
    font-size: 1.5rem;
    color: black;
  }
}
