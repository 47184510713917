.opportunityWrapper {
  display: flex;
  flex-direction: column;
  height: 100vh;
  width: 100%;
  overflow-x: hidden;
}
.opportunityHeader {
  position: sticky;
  top: 0;
  z-index: 50;
}
.opportunityBottom {
  display: flex;
  width: 100%;
  height: 100%;
  background-color: #f5f7fa;
  overflow: auto;
}
.opportunitySidebar {
  min-width: 20%;
  height: 100%;
  position: sticky;
  top: 0;
}
.opportunityRightUpdated {
  display: flex;
  justify-content: space-evenly;
  min-width: 80%;
  flex-direction: row;
}
.availableJobs {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100%;
  padding: 1rem;
  min-width: 60%;
}
.aiOpportunities {
  padding: 1rem;
  min-width: 30%;
  position: sticky;
  top: 0;
  align-self: right;
}

@media screen and (max-width: 800px) {
  .opportunityWrapper {
    display: flex;
    flex-direction: column;
    height: auto;
    width: 100%;
  }
  .opportunitySidebar {
    display: none;
    width: 0%;
  }
  .opportunityRightUpdated {
    display: flex;
    flex-direction: column;
    justify-content: space-evenly;
    min-width: 100%;
  }
}
