.formInputWrapper {
  position: relative;
  width: 99%;
  /* height: 45px; */
  display: flex;
  justify-content: space-between;
  margin: 20px auto;
  border: 1px solid;
  border-radius: 8px;
  background-color: white;
}

.formInputWrapper input {
  width: calc(100% - 16px);
  padding: 8px;
  border: none;
  border-radius: 3px;
  outline: none;
  opacity: 0.8;
  box-sizing: border-box; /* Include padding and border in the element's total width */
}

.inputFlatingLabel {
  position: absolute;
  z-index: 100;
  left: 1rem;
  top: 0;
  transform: translateY(-50%);
  transition: all 0.5s ease;
  font-size: 1.7rem;
  color: grey;
  padding: 0.1rem;
  background-color: white;
}
