.missionWrapper {
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  background: #0c0f47;
  color: white;
  background-image: url("../../../assets/footerBg.png");
  background-repeat: no-repeat;
  background-size: cover;
}
.missionHeading {
  width: 70%;
  font-size: 6.4rem;
  font-weight: 700;
  text-align: center;
  padding: 2rem;
}

.missionInfo {
  width: 80%;
  text-align: center;
  font-size: 2.4rem;
  font-weight: 400;
}

@media screen and (max-width: 768px) {
  .teamMembers {
    display: grid;
    grid-template-columns: repeat(1, 1fr);
    row-gap: 5vh;
    width: 90%;
    margin: auto;
    column-gap: 3vh;
  }
  .missionHeading {
    width: 70%;
    font-size: 3rem;
    font-weight: 700;
    text-align: center;
    padding: 2rem;
  }
  .missionInfo {
    width: 90%;
    text-align: center;
    font-size: 2rem;
    font-weight: 400;
    padding: 1rem;
  }
  .mvImgWrapper{
    display: flex;
    justify-content: center;
    align-items: center;
    padding: 2rem;
  }
  .mvImg{
    width:50%;
  }
}
