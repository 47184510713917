.headerWrapperForms {
  display: flex;
  background: #04063a;
  width: 100%;
  justify-content: space-between;
  color: white;
  font-weight: 400;
  position: -webkit-sticky;
  position: sticky;
  top: 0;
  z-index: 100;
  padding: 1.5rem 0;
}

.headerLogoForms {
  width: 10%;
  padding: 0.5rem;
  display: flex;
  justify-content: center;
  align-items: center;
}
.headerLogoFormsImg {
  width: 50%;
}

.userDetailsHeader {
  display: flex;
  justify-content: space-around;
  align-items: center;
  padding-right: 3rem;
  width: 20%;
}

.userName {
  padding: 1rem;
  font-size: 2rem;
}

/* Media query for mobile devices */
@media only screen and (max-width: 768px) {
  .headerWrapperForms {
    padding: 0rem;
  }
  .headerLogoForms {
    width: 20%;
    padding: 1rem;
    display: flex;
    justify-content: center;
    align-items: center;
  }
  .headerLogoFormsImg {
    width: 100%;
  }
  .userDetailsHeader {
    width: 60%;
    padding: 1rem 3rem 1rem 1rem; /* Add padding for spacing on smaller screens */
  }
  /* Add these styles to your existing CSS file */
  .iconContainer {
    display: flex;
    justify-content: center;
    align-items: center;
    padding: 0.2rem;
  }

  .icon {
    width: 18px; /* Adjust the size as needed */
    height: 18px;
  }

  .profileIcon {
    width: 18px; /* Adjust the size as needed */
    height: 18px;
    border-radius: 50%; /* Make the profile image round */
  }

  .userName {
    padding: 0.2rem;
    font-size: 1.8rem;
    font-weight: 500;
  }

  /* Add these styles to your existing CSS file */

  .logoutButton {
    background: linear-gradient(
      45deg,
      #4caf50,
      #2196f3
    ); /* Gradient background */
    color: #ffffff; /* White text */
    border: none;
    padding: 0.2rem 0.5rem; /* Adjust padding as needed */
    cursor: pointer;
    border-radius: 4px; /* Rounded corners */
    transition: background 0.3s, color 0.3s, box-shadow 0.3s;
    box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1); /* Subtle box-shadow */
    font-size: 1.8rem;
  }

  .logoutButton:hover {
    background: linear-gradient(
      45deg,
      #2196f3,
      #4caf50
    ); /* Gradient background on hover */
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.2); /* Slightly stronger box-shadow on hover */
  }
}
