.footerWrapper {
  background-image: url("../../assets/footerBg.png");
  background-repeat: no-repeat;
  background-size: cover;
  color: #ffffff;
}

.footerMain {
  display: flex;
  justify-content: space-around;
  min-height: 40vh;
  font-size: 4rem;
  width: 100%;
}

.brochureSection,
.navSection,
.contactSection {
  flex: 1 0 calc(33.33% - 10rem);
}

.brochureSection {
  display: flex;
  flex-direction: column;
  justify-content: center;
  padding: 1rem;
  margin-right: 6rem;
}

.brochureSection .footerDescription {
  max-width: 40ch;
  font-size: 2rem;
  font-weight: 800;
  padding: 2rem;
  opacity: 0.8;
}
.leadInfo {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}

.footerLogoCon {
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 2rem;
}

.footerLogoImg {
  width: 80%;
}

.brochureButton {
  font-size: 2rem;
  background-color: #dfa114;
  padding: 1rem;
  border-radius: 5px;
  outline: none;
  border: none;
  font-weight: bold;
  max-width: 35rem;
}

.navSection,
.contactSection {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}

.footerNavLink {
  padding: 1rem;
  font-size: 2.5rem;
  font-weight: 800;
}

.contactMode {
  display: flex;
}

.contactIcon {
  object-fit: contain;
}

.contactInfo {
  display: flex;
  flex-direction: column;
  padding: 1rem 1.5rem;
  justify-content: center;
  align-items: center;
}

.footerBottom {
  padding-top: 1rem;
  text-align: center;
}

.copyRight {
  padding: 1rem;
  opacity: 0.6;
}

hr.separator {
  width: 80%;
  min-width: 360px;
  color: #ffffff;
  margin: auto;
  opacity: 0.6;
}

.opguruHandles {
  display: flex;
  width: 100%;
  justify-content: center;
}
.opguruHandle {
  padding: 1rem;
}

.icon-link {
  text-decoration: none; /* Remove underline if needed */
}

.icon-link:visited,
.icon-link:active {
  text-decoration: none;
}

@media screen and (max-width: 768px) {
  .footerMain {
    flex-direction: column;
    padding: 0rem;
  }

  .brochureSection {
    margin-right: 0;
    align-items: center;
    text-align: center;
  }

  .navSection,
  .contactSection {
    text-align: center;
    margin: 3.5rem 0;
  }
  .footerNavLink {
    font-size: 1.5rem;
  }

  .contactMode {
    text-align: center;
    margin: 1rem;
  }

  .contactIcon {
    margin: auto;
    width: 8%;
  }
  .contactInfo {
    display: flex;
    justify-content: center;
    align-items: center;
  }
  .footerLogoImg {
    width: 60%;
  }
  .footerDescription {
    font-size: 0.2rem;
    /* margin-bottom: 1rem; */
  }
  .brochureButton {
    color: black;
    font-size: 1.5rem;
  }
  .copyRight {
    font-size: 1.4rem;
  }
}
