.personCardWrapper {
  display: flex;
  text-align: center;
  flex-direction: column;
  border-radius: 8px;
  background: white;
  padding: 3rem 1rem;
  color: black;
  width: 80%;
  height: 350px;
  margin: auto;
}

.personName {
  font-size: 2.2rem;
  font-weight: 700;
  padding: 1rem;
}
.personJob {
  font-size: 2rem;
  font-weight: 400;
  padding: 1rem;
}
.personInfo {
  font-size: 1.8rem;
  font-weight: 300;
  padding: 1rem;
}
.personCompanyImg {
  display: flex;
  justify-content: center;
  align-items: center;
}

@media screen and (max-width: 800px) {
  .personName {
    font-size: 1.8rem;
    font-weight: 700;
    padding: 1rem;
  }
  .personJob {
    font-size: 1.6rem;
    font-weight: 400;
    padding: 1rem;
  }
  .personInfo {
    font-size: 1.4rem;
    font-weight: 300;
    padding: 1rem;
  }
}
