.registerWrapper {
  display: flex;
  flex-direction: row;
  width: 100%;
}
.messageLogin {
  width: 49%;
}

.userRegisterForm {
  font-family: "Rogan";
  width: 51%;
  display: flex;
  flex-direction: column;
  height: 100vh;
  justify-content: space-evenly;
  align-items: center;
}

.midWrapperRegister {
  display: flex;
  flex-direction: column;
  justify-content: space-evenly;
  align-items: center;
  min-height: 70%;
  width: 50%;
}

.authMessage {
  display: flex;
  justify-content: center;
  padding-bottom: 1rem;
}

.authMessageText {
  display: flex;
  justify-content: space-between;
  text-align: center;
  color: #010334;
  font-size: 4rem;
  font-weight: 700;
  padding-right: 1rem;
}

.opguruLogoAuth:hover {
  cursor: pointer;
}

.welcomeMessage {
  display: flex;
  justify-content: center;
  text-align: center;
}

.nameWrap {
  width: 100%;
}

.termsConditions {
  width: 100%;
  text-align: center;
  font-size: 1.4rem;
  padding: 0.5rem;
}

.changeCol {
  color: #dfa114;
}

.font40 {
  font-size: 4rem;
  font-weight: 700;
}

#registerForm {
  display: flex;
  flex-direction: column;
  justify-content: space-evenly;
  padding-bottom: 1rem;
  width: 100%;
  height: 80%;
}

.phonenumEnter {
  width: 80%;
}

.phonenumWrap {
  display: flex;
  width: 100%;
  flex-direction: column;
  justify-content: space-around;
}

.PhoneInputInput {
  border: 1px solid #010334;
  border-radius: 8px;
  padding: 1rem;
  background-color: rgba(255, 255, 255, 0.4);
}

.PhoneInputInput:focus {
  opacity: 0.8;
}

#recaptcha-container {
  width: 100% !important;
  padding-top: 2rem;
}

.loginButton {
  display: flex;
  justify-content: center;
  background-color: #dfa114;
  width: 100%;
}

.methodChoice,
.alreadyLogin {
  display: flex;
  justify-content: center;
  margin: 0.5rem;
}

.googleSignUp {
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 70%;
}

.googleButton {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
}

.googleIcon {
  width: 12%;
}

.GoogleImg {
  width: 100%;
}

.googleMessage {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 80%;
}

.verifyOTP {
  width: 51%;
  display: flex;
  flex-direction: column;
  height: 100vh;
  justify-content: space-evenly;
  align-items: center;
}

.OtpImgCon {
  display: flex;
  width: 30%;
  justify-content: center;
  align-items: center;
  margin: 0.5rem;
}

.OtpImg {
  width: 100%;
}

.otpMessage {
  display: flex;
  justify-content: center;
  color: #010334;
  font-size: 4rem;
  font-weight: 700;
  margin: 1rem;
  text-align: center;
}

.OtpSent {
  text-align: center;
}

.otpSubmitButton {
  display: flex;
  justify-content: center;
  align-items: center;
}

.otpBtn {
  display: flex;
  justify-content: center;
  background-color: #dfa114;
  width: 100%;
}

.instructions {
  font-size: 1.5rem;
  border-radius: 0.5rem;
  background: #000;
  color: #fff;
  padding: 0.25rem;
  position: relative;
  bottom: -10px;
}

.instructions > svg {
  margin-right: 0.25rem;
}

.offscreen {
  position: absolute;
  left: -9999px;
}

.hide {
  display: none;
}

.valid {
  color: limegreen;
  margin-left: 0.25rem;
}

.invalid {
  color: red;
  margin-left: 0.25rem;
}

.errmsg {
  background-color: lightpink;
  color: firebrick;
  font-weight: bold;
  padding: 0.5rem;
  margin-bottom: 0.5rem;
}

.line {
  display: inline-block;
}

.linkStyle1 {
  display: flex;
  justify-content: center;
  align-items: center;
  padding-left: 0.4rem;
}

@media only screen and (max-width: 1200px) {
  /*Tablets [601px -> 1200px]*/
}
@media only screen and (max-width: 600px) {
  /*Big smartphones [426px -> 600px]*/
}
@media only screen and (max-width: 450px) {
  .messageLogin {
    display: none;
  }

  .userRegisterForm {
    width: 82%;
    margin: auto;
  }

  .midWrapperRegister {
    width: 100%;
  }

  #registerForm {
    height: 55%;
  }

  .registerMessage {
    width: 90%;
    margin: auto;
    display: block;
    font-size: 3rem;
  }
  .authMessageText {
    display: flex;
    justify-content: center;
    align-items: center;
    text-align: center;
    color: #010334;
    font-size: 2.8rem;
    font-weight: 700;
    padding-right: 1rem;
  }
  .changeCol {
    display: inline;
    font-size: 3rem;
  }
  .welcomeMessage {
    font-size: 1.6rem;
  }
  .verifyOTP {
    width: 80%;
    margin: auto;
  }
}
