.resumePickerWrapper {
  color: black;
  width: 30%;
}
.resumeBtn {
  height: 120px;
  width: 120px;
  background-color: none;
  background: none;
  color: black;
  border: 2px dashed rgba(0, 0, 0, 0.5); /* Light black color with 30% opacity */
}
.pickerInput {
  display: none;
}
