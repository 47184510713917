.brandsWrapper {
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  justify-content: space-around;
  align-items: center;
  background: #0c0f47;
  color: white;
  background-image: url("../../../assets/footerBg.png");
  background-repeat: no-repeat;
  background-size: cover;
}

.brandsHeading1 {
  width: 90%;
  font-size: 6.4rem;
  font-weight: 700;
  text-align: center;
  padding: 2rem;
}
.brandsHeading2 {
  font-weight: 300;
  font-size: 2.4rem;
  text-align: center;
  width: 50%;
  padding: 2rem;
}
.brandImages {
  display: flex;
  flex-direction: column;
  justify-content: space-around;
  min-height: 60vh;
  width: 100%;
  overflow: hidden;
}
.brandImagesTop {
  width: 120%;
  margin-left: -2rem;
  display: flex;
  justify-content: space-around;
}
.brandImagesMiddle {
  width: 120%;
  margin-left: -5rem;
  display: flex;
  justify-content: space-around;
}
