.multiSelectDropdownWrapper {
  width: 99%; /* Adjust width as needed */
  border: 1px solid black;
  border-radius: 10px;
}

.multiSelectDropdownToggle {
  position: relative;
  display: flex;
  justify-content: space-between;
  padding: 1rem;
  /* background-color: #fff; */
  cursor: pointer;
  display: flex;
  align-items: center;
  gap: 5px;
}

.multiSelectDropdownToggle span {
  margin-right: 5px;
}

.multiSelectDropdownMenu {
  width: 100%;
  max-height: 200px; /* Adjust max height as needed */
  overflow-y: auto;
  background-color: #fff;
  z-index: 1000;
  border-radius: 4px;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
  padding: 10px 0;
}

.selectAllButton {
  width: 80%;
  border-radius: 10px;
  margin: 1rem;
  padding: 0.5rem;
  background-color: #dfa114;
  color: black;
  border: none;
  cursor: pointer;
  text-align: center;
}

.searchAndSelectAll {
  display: flex;
  width: 100%;
}

.dropdownSearchInput {
  width: 90%;
  padding: 1rem;
  border: 1px solid #ccc;
  border-radius: 10px;
}

.searchBarMultiDropdown {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 80%;
}

.selectAllMultiDropdown {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 20%;
}

ul {
  list-style-type: none;
  padding: 0;
  margin: 0;
}

li {
  padding: 8px 10px;
  cursor: pointer;
  display: flex;
  align-items: center;
}

li.selected {
  background-color: #fff;
}

li:hover {
  background-color: #ccc;
}

.tickIcon {
  color: #4a90e2;
  margin-right: 10px;
}

.selectedOption {
  background-color: #ccc;
  color: black;
  padding: 5px 10px;
  border-radius: 4px;
  display: flex;
  align-items: center;
  z-index: 100;
}

.selectedMultiOptions {
  display: flex;
  flex-wrap: wrap;
}

.removeOption {
  background: none;
  border: none;
  color: white;
  cursor: pointer;
  margin-left: 5px;
  font-size: 12px;
}

.moreOptions {
  background-color: #ccc;
  color: black;
  padding: 5px 10px;
  border-radius: 4px;
}

.moreOptions,
.selectedOption {
  margin-bottom: 5px;
}
