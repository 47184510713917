.testimonialWrapper {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  height: 100vh;
  background-image: url("../../../assets/footerBg.png");
  background-repeat: no-repeat;
  background-size: cover;
  color: white;
  overflow: hidden;
}

.testiHeading1 {
  font-size: 5.4rem;
  font-weight: 700;
  text-align: center;
  padding: 1rem;
}
.testiHeading2 {
  font-weight: 300;
  font-size: 2.4rem;
  margin-bottom: 2rem;
  padding: 1rem;
  text-align: center;
}
.testiCarousel {
  width: 100%;
  display: flex;
  justify-content: space-around;
  /* min-height: 60%; */
  /* margin: auto; */
}
.testiCarouselDiv {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 65%;
}
.testiImage {
  width: 30%;
}
@media only screen and (max-width: 768px) {
  .testimonialWrapper {
    height: 100vh;
    padding-bottom: 2rem;
  }
  .testiCarousel {
    width: 100%;
    /* min-height: 60%; */
    /* margin: auto; */
  }
  .testiHeading1 {
    font-size: 3rem;
    padding: 2rem;
  }
  .testiHeading2 {
    font-size: 2rem;
  }
  .testiImage {
    display: none;
  }
}
