.internship-form {
  background-color: #f9f9f9;
  border-radius: 8px;
  padding: 20px;
  margin-bottom: 20px;
  box-shadow: 0 2px 5px rgba(0, 0, 0, 0.1);
}

.field-row {
  display: flex;
  gap: 15px;
  flex-wrap: wrap;
  margin-bottom: 15px;
}

.field-row input,
.field-row select {
  flex: 1;
  padding: 10px;
  font-size: 16px;
  border: 1px solid #ccc;
  border-radius: 4px;
  transition: border-color 0.2s;
}

.field-row input:focus,
.field-row select:focus {
  border-color: #007bff;
}

/* Custom styling for month input placeholders */
.field-row input[type="month"]::before {
  color: #888;
  content: attr(placeholder);
}

.checkbox-container {
  display: flex;
  align-items: center;
}

.checkbox-container label {
  margin-left: 8px;
  font-size: 14px;
}

.add-more-btn {
  width: 100%;
  background-color: #dfa114;
  color: black;
  padding: 8px 12px;
  border: none;
  border-radius: 4px;
  cursor: pointer;
  font-size: 14px;
  transition: background-color 0.2s;
}

.add-more-btn:hover,
.remove-btn:hover {
  background-color: #0056b3;
}

.remove-btn {
  background-color: #dc3545;
  margin-top: 10px;
  color: black;
  padding: 8px 12px;
  border: none;
  border-radius: 4px;
  cursor: pointer;
  font-size: 14px;
  transition: background-color 0.2s;
}

.remove-btn:hover {
  background-color: #c82333;
}
