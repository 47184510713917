.headerWrapperProfile {
  display: flex;
  background: #04063a;
  width: 100%;
  justify-content: space-between;
  color: white;
  font-weight: 400;
  position: -webkit-sticky;
  position: sticky;
  top: 0;
  z-index: 100;
  padding: 1.5rem;
}

.userDetailsHeaderProfile {
  display: flex;
  justify-content: space-around;
  align-items: center;
  padding-right: 3rem;
  width: 20%;
}

.headerLeftProfile {
  width: 10%;
  padding-left: 4rem;
}
.headerLogoProfile {
  display: flex;
  justify-content: center;
  align-items: center;
}
.headerLogoProfileImg {
  width: 60%;
}
.hamburgerProfile {
  display: flex;
  justify-content: center;
  align-items: center;
}
@media only screen and (max-width: 768px) {
  .headerWrapperProfile {
    padding: 0;
  }
  .userDetailsHeaderProfile {
    width: 60%;
    padding: 1.5rem 3rem 1.5rem 1.5rem; /* Add padding for spacing on smaller screens */
    justify-content: space-between;
  }
  .headerLeftProfile {
    width: 25%;
    padding-left: 0rem;
    display: flex;
  }
  .headerLogoProfileImg {
    width: 110%;
  }
}
