.ResetSuccess {
  display: flex;
}

.ResetSuccessRight {
  font-family: "Rogan";
  width: 51%;
  display: flex;
  flex-direction: column;
  height: 100vh;
  justify-content: space-evenly;
  align-items: center;
}
.midWrapperResetSuccess {
  display: flex;
  flex-direction: column;
  justify-content: space-evenly;
  align-items: center;
  height: 60%;
  width: 50%;
}
.ResetSuccessMsg {
  text-align: center;
}

@media only screen and (max-width: 600px) {
  .ResetSuccessRight {
    margin: auto;
    width: 90%;
  }
  .midWrapperResetSuccess {
    height: 80%;
    width: 100%;
  }
}
