.founderWrapper {
  display: flex;
  flex-direction: column;
  /* justify-content: space-around; */
  margin: auto;
  height: 100%;
  padding: 1rem;
  box-shadow: 2;
}
.founderImgWrapper {
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 1rem;
  width: 30%;
  margin: auto;
}
.founderImg {
  width: 100%;
  /* height: 100%; */
  /* border-radius: 50%; */
  /* width:100%; */
}
.founderInformation {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  padding: 1rem;
  width: 70%;
  margin: auto;
  /* height: 100%; */
}
.founderName {
  font-size: 2.1rem;
  font-weight: 800;
  padding: 0.5rem;
}
.founderRole {
  font-size: 1.6rem;
  font-weight: 700;
  padding: 0.5rem;
}
.founderInfo {
  width: 95%;
  font-size: 1.6rem;
  font-weight: 500;
  padding: 0.5rem;
  text-align: center;
}
.founderHandles {
  display: flex;
  padding: 1rem;
}
.founderHandle {
  padding: 0.5rem;
}
