.userFormWrapper {
  overflow: auto;
}

.studentFormWrapper {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  border-radius: 8px;
  box-sizing: border-box;
  background: #ffffff;
  width: 43.75%;
  margin: auto;
  height: 80%;
  margin-bottom: 10rem;
  margin-top: 3rem;
  overflow: auto;
}

.nameWrapper {
  display: flex;
  width: 100%;
}

.inputForm {
  margin: 1rem;
  height: 100%;
}

.firstName,
.lastName {
  width: 50%;
}

.formHeading {
  font-size: 3.2rem;
  text-align: center;
}

.formMessage {
  text-align: center;
}
