.regionsWrapper {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  width: 100%;
}
.addedRegionsCon {
  width: 80%;
}
.addMoreRegionCon {
  width: 80%;
}
.addMoreRegionBtn {
  font-size: 2rem;
  border-radius: 8px;
  padding: 1rem;
  border: 1px solid;
  margin: 1rem 3rem 1rem 3rem;
  border: 1px black solid;
  color: black;
  background-color: white;
  /* text-align: center; */
  /* width: 100%; */
}

.addedRegionCon {
  align-self: flex-start;
  width: 80%;
}

@media only screen and (max-width: 768px) {
  .addedRegionsCon {
    width: 95%;
  }
  .addMoreRegionCon {
    width: 85%;
  }
  .addMoreRegionBtn {
    margin: 0;
  }
}
