.profile-card {
  position: relative;
  width: 300px; /* Adjust as needed */
  color: white;
  border-radius: 10px;
  overflow: hidden;
  background-color: black; /* Fallback color */
}

.profile-card::before {
  content: "";
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-image: url("../../../../assets/Himanshu.jpeg");
  background-repeat: no-repeat;
  background-size: cover;
  background-position: center;
  filter: grayscale(100%);
  z-index: 1;
}

.profile-content {
  position: relative;
  z-index: 2;
  padding: 20px;
  text-align: center;
}

.background-image {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  z-index: 1;
  filter: grayscale(100%); /* Make the image black and white */
  opacity: 0.6; /* Optional: make the background image slightly transparent */
}

.profile-background-image {
  width: 100%;
  height: 100%;
  object-fit: cover;
}

.profile-info {
  margin-bottom: 20px;
}

.profile-name {
  font-size: 1.5rem;
  margin: 0;
  color: #ffcc00;
}

.profile-position {
  font-size: 1.2rem;
  margin: 5px 0;
  color: #fca311;
}

.linkedin-container {
  position: relative;
  width: 70px; /* Adjust size as needed */
  margin: 0 auto;
  margin-bottom: 15px;
}

.profile-image {
  width: 100%;
  border-radius: 50%;
  border: 3px solid white;
}

.linkedin-icon {
  position: absolute;
  bottom: 0;
  right: 0;
  width: 20px; /* Adjust size as needed */
  border-radius: 50%;
  border: 2px solid white;
  background-color: white;
}

.profile-mission {
  font-size: 1rem;
  line-height: 1.5;
  font-style: italic;
  color: white;
}
