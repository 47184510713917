.oppCardWrapper {
  box-sizing: border-box;
  background: #ffffff;
  box-shadow: 0px 0px 30px rgba(28, 32, 98, 0.12);
  border-radius: 6px;
  padding: 2rem;
}

.oppCardTop {
  display: flex;
  justify-content: center;
  border-bottom: #e6e6e6;
}
.oppCardType {
  display: flex;
  width: 70%;
}
.oppCardImgCon {
  width: 30%;
  display: flex;
  justify-content: center;
  align-items: center;
}
.oppCardImg {
  width: 100%;
}
.oppCardName {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 70%;
  font-size: 1.6rem;
  font-weight: 600;
  padding: 1rem;
}
.oppCardCount {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 20%;
  font-weight: 700;
  font-size: 3.6rem;
}
.statusCount {
  font-size: 2.2rem;
  font-weight: 700;
}
.statusType {
  font-size: 1.4rem;
  font-weight: 600;
}
.oppCardBottom {
  width: 100%;
  display: flex;
}
.oppActivated,
.oppDeactivated {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  width: 50%;
}

@media screen and (max-width: 800px) {
  .oppCardWrapper {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    min-height: 300px;
  }
  .oppCardTop {
    flex-direction: column;
    margin-bottom: 2rem;
    height:200px;
    justify-content: space-around;
  }
  .oppCardImgCon {
    width: 100%;
  }
  .oppCardImg {
    width: 70%;
  }
  .oppCardCount {
    width: 100%;
    font-weight: 700;
    font-size: 2.5rem;
  }
  .oppCardType {
    flex-direction: column;
    width:100%;
    text-align: center;
  }
  .oppCardName {
    width:100%;
    padding: 0 0 1rem 0;
  }
  .oppCardBottom {
    flex-direction: column;
    height:100px;
  }
  .oppActivated,
.oppDeactivated {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  width: 100%;
}
}
