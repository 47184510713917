/* CSS for the EditSkillsModal component */
/* index.css */
.modal {
  display: none;
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: rgba(0, 0, 0, 0.5);
  justify-content: center;
  align-items: center;
}

.filter-input {
  /* Add your styles for the filter input here */
  background-color: white;
  padding: 8px;
  border: 1px solid #ccc;
  border-radius: 4px;
  margin-bottom: 10px;
  /* Add more styles as needed */
}

.skill-option {
  padding: 8px;
  margin: 4px;
  cursor: pointer;
  border: 1px solid #ccc;
  border-radius: 10px;
  transition: background-color 0.3s;
}

.skill-option.selected {
  background-color: #dfa114;
}
.bold-text {
  font-size: 2.4rem;
  font-weight: bold;
  margin-bottom: 20px;
}
.skillsWrapper {
  display: flex;
  flex-wrap: wrap;
  gap: 16px; /* Adjust the spacing between items */
  justify-content: center; /* Center items horizontally in the container */
}

.skill-option {
  display: flex;
  justify-content: center;
  align-items: center;
  margin: 5px; /* Adjust the value as needed to control the spacing */
  margin-bottom: 20px;
  min-width: 20%;
}
.modalContentButton {
  background-color: #dfa114;
  font-size: 25px;
  padding: 10px 20px;
  margin-top: 15px;
  cursor: pointer;
  border: 0px solid #ccc;
  width: 360px;
  border-radius: 4px;
}

.modalSaveButton {
  display: flex;
  justify-content: center;
  font-size: 2rem;
  font-weight: 700;
  background-color: #dfa114;
  width: 50%;
  border: none;
  border-radius: 6px;
  padding: 1rem;
  cursor: pointer;
  color: black;
}

@media screen and (max-width: 768px) {
  .skillsWrapper {
    display: grid;
    grid-template-columns: repeat(2, 1fr); /* 4 columns */
    gap: 16px; /* Adjust the spacing between items */
  }
  .skill-option {
    display: flex;
    justify-content: center;
    align-items: center;
    margin: 2px; /* Adjust the value as needed to control the spacing */
    margin-bottom: 20px;
    min-width: 85%;
    padding: 12px;
  }
  .modalSaveButton {
    width: 80%;
    margin: auto;
  }
  .modal-content-profile {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    align-items: center;
    position: fixed;
    overflow-y: scroll;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    line-height: 1.4;
    background: #ffffff;
    padding: 14px 28px;
    border-radius: 3px;
    width: 40%;
    max-height: 80%;
    padding: 3rem;
    width: 75%;
    min-width: 50%;
    box-shadow: 0 2px 10px rgba(0, 0, 0, 0.1);
    height: 50%;
  }
}
