.opportunityDetailsRight {
  display: flex;
  min-width: 80%;
}

.opportunityInformation {
  display: flex;
}

.loaderWrapper {
  margin: auto;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}

.oppDetails {
  width: 40%;
  margin: 2rem;
  border-radius: 10px;
}
.mentors {
  width: 55%;
  margin: 2rem;
  border-radius: 10px;
}

@media screen and (max-width: 800px) {
  .opportunityDetailsRight {
    display: flex;
    flex-direction: column;
    min-width: 80%;
    justify-content: start;
    align-items: center;
  }
  .opportunityInformation {
    display: flex;
    flex-direction: column;
  }
  .oppDetails {
    width: 90%;
  }
  .mentors {
    width: 90%;
  }
}
