.faq-container {
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  width: 100%;
  background-image: url("../../../assets/footerBg.png");
  background-size: cover;
  color: white;
  margin: 0;
  margin: auto;
}

.faq-item {
  color: #333333;
  width: 50%;
  background-color: #f7f7f7;
  border-radius: 10px;
  margin: 15px 0;
  padding: 15px 20px;
  cursor: pointer;
  transition: background-color 0.3s;
}

.faq-item:hover {
  background-color: #e0e0e0;
}

.faq-question {
  font-size: 2.2rem;
  font-weight: 700;
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin: 0;
}

.faq-answer {
  padding-top: 10px;
  font-size: 2rem;
  line-height: 1.5;
}

.faq-icon {
  font-size: 1.5rem;
  transition: transform 0.4s;
}

.active .faq-icon {
  transform: rotate(90deg);
}

@media screen and (max-width: 768px) {
  .faq-item {
    width: 70%;
  }
  .faq-question {
    font-size: 1.8rem;
    font-weight: 700;
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin: 0;
  }
}
