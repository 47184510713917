/* EditProfileModal.css */
.modal {
  display: none;
  position: fixed;
  top: 0;
  left: 0;
  width: 80%;
  height: 100%;
  background: rgba(0, 0, 0, 0.5);
  justify-content: center;
  align-items: center;
  z-index: 1;
}

.modal.open {
  display: flex;
}

.modal-content {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  background: white;
  padding: 4rem;
  border-radius: 5px;
  width: 60%;
  min-width: 50%;
}

.close-button {
  position: absolute;
  top: 10px;
  right: 10px;
  cursor: "pointer";
}
.crossBtnImg {
  width: 32px;
  height: 32px;
}

.inputEditProfile {
  width: 80%;
}

@media screen and (max-width: 768px) {
  .modal-content {
    display: block;
    height: 60%;
    overflow-y: auto;
  }
}
