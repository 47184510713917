.addedRegionWrapper {
  width: 100%;
  padding: 2rem;
}

.regionName {
  padding: 1rem;
}

@media only screen and (max-width: 768px) {
  .addedRegionWrapper {
    width: 100%;
    padding: 0.5rem;
  }
}
