.categoryWrapper {
  height: 100vh;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}
.categoryHeading1 {
  width: 70%;
  font-size: 6.4rem;
  font-weight: 700;
  text-align: center;
  padding: 2rem;
}
.categoryHeading2 {
  font-weight: 300;
  font-size: 2.4rem;
  text-align: center;
  width: 37.5%;
  padding: 2rem;
}

.categories {
  display: flex;
  width: 80%;
  padding: 4rem;
}

@media screen and (max-width: 768px) {
  .categoryWrapper {
    height: auto;
  }
  .categoryHeading1 {
    font-size: 3rem;
    width: 80%;
  }
  .categoryHeading2 {
    font-size: 2rem;
    width: 70%;
  }
  .categories {
    flex-direction: column;
    padding: 2rem;
    width: 80%;
  }
  .eachCategory {
    border-radius: 1rem;
    box-shadow: 0 0 10px 0 rgba(0, 0, 0, 0.1);
    margin: 2rem 0;
    padding: 2rem 0;
  }
}
