.blogs-grid {
  display: grid;
  grid-template-columns: repeat(auto-fill, minmax(300px, 1fr));
  gap: 30px;
  padding: 30px;
  max-width: 1400px;
  margin: 0 auto;
}

/* Blog Card */
.blog-card {
  background: white;
  border-radius: 12px;
  overflow: hidden;
  box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
  transition: transform 0.3s ease, box-shadow 0.3s ease;
  cursor: pointer;
  height: 100%;
  display: flex;
  flex-direction: column;
}

.blog-card:hover {
  transform: translateY(-5px);
  box-shadow: 0 8px 12px rgba(0, 0, 0, 0.15);
}

/* Blog Card Image */
.blog-card-image {
  width: 100%;
  height: 200px;
  overflow: hidden;
  position: relative;
}

.blog-card-image img {
  width: 100%;
  height: 100%;
  object-fit: cover;
  transition: transform 0.3s ease;
}

.blog-card:hover .blog-card-image img {
  transform: scale(1.05);
}

/* Blog Card Content */
.blog-card-content {
  padding: 20px;
  flex-grow: 1;
  display: flex;
  flex-direction: column;
}

.blog-card-title {
  font-size: 20px;
  font-weight: 600;
  color: #333;
  margin-bottom: 12px;
  line-height: 1.4;
}

.blog-card-introduction {
  font-size: 14px;
  color: #666;
  line-height: 1.6;
  margin-bottom: 20px;
  flex-grow: 1;
}

/* Blog Card Footer */
.blog-card-footer {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-top: auto;
  padding-top: 15px;
  border-top: 1px solid #eee;
}

.blog-card-date {
  font-size: 12px;
  color: #888;
}

.blog-card-read-more {
  color: #0066cc;
  font-size: 14px;
  font-weight: 500;
  transition: color 0.2s ease;
}

.blog-card:hover .blog-card-read-more {
  color: #0052a3;
}

/* Loading State */
.blogs-loading {
  text-align: center;
  padding: 50px;
  font-size: 18px;
  color: #666;
}

/* Error State */
.blogs-error {
  text-align: center;
  padding: 50px;
  color: #dc3545;
  font-size: 18px;
}

/* Empty State */
.blogs-empty {
  text-align: center;
  padding: 50px;
  color: #666;
  font-size: 18px;
}

/* Responsive Design */
@media (max-width: 768px) {
  .blogs-grid {
    grid-template-columns: 1fr;
    padding: 20px;
  }

  .blog-card-image {
    height: 180px;
  }

  .blog-card-content {
    padding: 15px;
  }

  .blog-card-title {
    font-size: 18px;
  }
}

/* Optional: Animation for cards appearing */
@keyframes fadeIn {
  from {
    opacity: 0;
    transform: translateY(20px);
  }
  to {
    opacity: 1;
    transform: translateY(0);
  }
}

.blog-card {
  animation: fadeIn 0.5s ease forwards;
}

/* Optional: Stagger the animation for each card */
.blog-card:nth-child(1) {
  animation-delay: 0.1s;
}
.blog-card:nth-child(2) {
  animation-delay: 0.2s;
}
.blog-card:nth-child(3) {
  animation-delay: 0.3s;
}
.blog-card:nth-child(4) {
  animation-delay: 0.4s;
}
.blogs-grid {
  display: grid;
  grid-template-columns: repeat(auto-fill, minmax(300px, 1fr));
  gap: 2rem;
  padding: 2rem;
}

.blog-card {
  background: white;
  border-radius: 12px;
  overflow: hidden;
  box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
  transition: transform 0.2s ease, box-shadow 0.2s ease;
  cursor: pointer;
}

.blog-card:hover {
  transform: translateY(-4px);
  box-shadow: 0 8px 12px rgba(0, 0, 0, 0.15);
}

.blog-card-image {
  width: 100%;
  height: 200px;
  overflow: hidden;
}

.blog-card-image img {
  width: 100%;
  height: 100%;
  object-fit: cover;
  transition: transform 0.3s ease;
}

.blog-card:hover .blog-card-image img {
  transform: scale(1.05);
}

.blog-card-content {
  padding: 1.5rem;
}

.blog-card-title {
  font-size: 1.5rem;
  font-weight: 600;
  color: #1a1a1a;
  margin-bottom: 1rem;
  line-height: 1.3;
}

.blog-card-introduction {
  color: #4a5568;
  line-height: 1.6;
  margin-bottom: 1.5rem;
}

.blog-card-footer {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding-top: 1rem;
  border-top: 1px solid #e2e8f0;
}

.blog-card-date {
  color: #718096;
  font-size: 0.9rem;
}

.blog-card-read-more {
  color: #3182ce;
  font-weight: 500;
  transition: color 0.2s ease;
}

.blog-card:hover .blog-card-read-more {
  color: #2c5282;
}

/* Pagination Styles */
.pagination-container {
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 2rem 0;
  gap: 0.5rem;
}

.pagination-button {
  display: flex;
  align-items: center;
  justify-content: center;
  min-width: 40px;
  height: 40px;
  padding: 0 0.75rem;
  border-radius: 8px;
  border: 1px solid #e2e8f0;
  background-color: white;
  color: #4a5568;
  font-weight: 500;
  transition: all 0.2s ease;
}

.pagination-button:hover:not(:disabled) {
  background-color: #f7fafc;
  border-color: #cbd5e0;
  color: #2d3748;
}

.pagination-button:disabled {
  background-color: #f7fafc;
  color: #a0aec0;
  cursor: not-allowed;
}

.pagination-button.active {
  background-color: #3182ce;
  border-color: #3182ce;
  color: white;
}

.pagination-button.active:hover {
  background-color: #2c5282;
  border-color: #2c5282;
}

.pagination-nav-button {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 40px;
  height: 40px;
  border-radius: 8px;
  border: 1px solid #e2e8f0;
  background-color: white;
  color: #4a5568;
  transition: all 0.2s ease;
}

.pagination-nav-button:hover:not(:disabled) {
  background-color: #f7fafc;
  border-color: #cbd5e0;
}

.pagination-nav-button:disabled {
  background-color: #f7fafc;
  color: #a0aec0;
  cursor: not-allowed;
}

/* Loading and Error States */
.blogs-loading,
.blogs-error,
.blogs-empty {
  text-align: center;
  padding: 3rem;
  color: #4a5568;
  font-size: 1.1rem;
}

.blogs-error {
  color: #e53e3e;
}

/* Responsive Adjustments */
@media (max-width: 768px) {
  .blogs-grid {
    grid-template-columns: 1fr;
    padding: 1rem;
  }
  
  .pagination-button {
    min-width: 36px;
    height: 36px;
    font-size: 0.9rem;
  }
  
  .pagination-nav-button {
    width: 36px;
    height: 36px;
  }
}
