.internCheckbox{
  display: flex;
  /* padding-bottom: 1.5rem; */
}
.checkBoxInput{
  width:10%;
}
.checkBoxText{
  width:90%;
}

.checkBoxRemoteIntern{
  display: flex;
  padding: 0.5rem;
}

.remoteText{
  padding: 0.5rem;
}

.internDWrapper,
.internForm {
  width: 100%;
  z-index:-1;
  margin:1rem 1rem 4rem 1rem;
  width:92%;
}
.internFormHeader {
  width: 100%;
  display: flex;
  justify-content: space-between;
}
.internFormBottom {
  width: 100%;
}

.inputIntern {
  margin-top: 2rem;
  width: 100%;
  margin-bottom: 2rem;
}
