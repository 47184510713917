.sidebarWrapper {
  display: flex;
  flex-direction: column;
  height: 100%;
  width: 100%;
  min-width: 240px;
  background: #0c0f47;
  background-image: url("../../../assets/footerBg.png");
  background-repeat: no-repeat;
  background-size: cover;
  background-position: center;
  font-family: "Rogan", sans-serif;
  color: rgba(255, 255, 255, 0.6);
  border-right: 1px solid rgba(255, 255, 255, 0.1);
}

.sidebarContent {
  display: flex;
  flex-direction: column;
  flex-grow: 1;
  padding: 2rem 0.5rem;
}

.sidebarRoutes {
  display: flex;
  flex-direction: column;
  gap: 0.75rem;
}

.sidebarRoute {
  display: flex;
  align-items: center;
  padding: 0.875rem 1.5rem;
  margin: 0 0.5rem;
  border-radius: 8px;
  transition: all 0.2s ease-in-out;
  position: relative;
  cursor: pointer;
  user-select: none;
  outline: none;
}

.sidebarRoute:focus-visible {
  box-shadow: 0 0 0 2px rgba(223, 161, 20, 0.5);
}

.sidebarRoute:hover {
  background-color: rgba(73, 76, 123, 0.8);
  color: rgba(255, 255, 255, 0.9);
  transform: translateX(4px);
}

.sidebarRoute.active {
  color: rgba(255, 255, 255, 1);
  background: linear-gradient(
    90deg,
    rgba(223, 161, 20, 0.15) 0%,
    rgba(223, 161, 20, 0.05) 100%
  );
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
}

.sidebarRoute.active::before {
  content: "";
  position: absolute;
  left: -0.5rem;
  top: 50%;
  transform: translateY(-50%);
  height: 60%;
  width: 3px;
  background-color: #dfa114;
  border-radius: 0 4px 4px 0;
}

.routeIcon {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 24px;
  height: 24px;
  margin-right: 1rem;
  opacity: 0.8;
  transition: opacity 0.2s ease;
}

.sidebarRoute:hover .routeIcon,
.sidebarRoute.active .routeIcon {
  opacity: 1;
}

.routeIcon img {
  width: 100%;
  height: 100%;
  object-fit: contain;
}

.routeName {
  font-size: 1.8rem;
  font-weight: 500;
  letter-spacing: 0.01em;
  white-space: nowrap;
}
