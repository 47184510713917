/* Wrapper for the entire dropdown */
.searchDropdownWrapper {
  position: relative;
  width: 100%;
  border-radius: 8px;
  width: 99%;
  border: 1px black solid;
  /* overflow: hidden; */
  z-index: 1;
}

/* Style for the label (placeholder) */
.searchDropdownLabel {
  position: absolute;
  z-index: 100;
  left: 1rem;
  top: 0;
  transform: translateY(-50%);
  transition: all 0.5s ease;
  font-size: 1.7rem;
  color: grey;
  padding: 0.1rem;
  background-color: white;
}

/* Label moves to the top when dropdown is active (open or selected) */
.searchDropdownWrapper.active .searchDropdownLabel,
.searchDropdownWrapper.hasValue .searchDropdownLabel {
  top: -1rem;
  font-size: 1.5rem;
  color: black;
}

/* The toggle button (or div) for the dropdown */
.searchDropdownToggle {
  display: flex;
  justify-content: space-between;
  padding: 1rem;
  cursor: pointer;
  font-size: 2rem;
}

/* Dropdown menu that appears when the toggle is clicked */
.searchDropdownMenu {
  background-color: white;
  border: 1px solid #ccc;
  border-top: none;
  max-height: 200px;
  overflow-y: auto;
  z-index: 1000;
  box-shadow: 0px 2px 5px rgba(0, 0, 0, 0.1);
  border-radius: 8px;
}

/* Input field for searching within the dropdown */
.dropdownSearchInput {
  width: 90%;
  padding: 1rem;
  margin: 1rem;
  box-sizing: border-box;
  outline: none;
  font-size: 2rem;
  border-radius: 8px;
}

/* Unordered list of options */
.searchDropdownMenu ul {
  list-style-type: none;
  padding: 0;
  margin: 0;
}

/* Each option in the dropdown */
.searchDropdownMenu ul li {
  padding: 10px;
  cursor: pointer;
  font-size: 2rem;
}

/* Hover effect for dropdown options */
.searchDropdownMenu ul li:hover {
  background-color: #f0f0f0;
}
