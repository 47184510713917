.blog-detail-container {
  width: 100%;
  background-color: #ffffff;
  min-height: 100vh;
}

.blog-detail-content {
  max-width: 1200px;
  margin: 0 auto;
  padding: 40px 20px;
}

/* Header Styles */
.blog-detail-header {
  margin-bottom: 60px;
  text-align: center;
}

.blog-detail-title {
  font-size: 48px;
  font-weight: 700;
  color: #1a1a1a;
  margin-bottom: 20px;
  line-height: 1.2;
}

.blog-detail-meta {
  margin-bottom: 20px;
}

.blog-detail-date {
  color: #666;
  font-size: 16px;
}

.blog-detail-separator {
  width: 100px;
  height: 2px;
  background-color: #0066cc;
  margin: 30px auto;
}

.blog-detail-introduction {
  font-size: 20px;
  line-height: 1.6;
  color: #444;
  margin: 0 auto;
}

/* Main Content Styles */
.blog-detail-main {
  display: flex;
  flex-direction: column;
  gap: 60px;
}

.blog-detail-section {
  margin-bottom: 40px;
}

.blog-detail-image-text-row {
  display: grid;
  grid-template-columns: 1fr 1fr;
  gap: 40px;
  align-items: center;
}

/* .blog-detail-image-text-row.reverse {
  direction: rtl;
} */

.blog-detail-image-text-row.reverse > * {
  direction: ltr;
}

.blog-detail-image {
  width: 100%;
  height: 400px;
  overflow: hidden;
  border-radius: 8px;
  box-shadow: 0 4px 12px rgba(0, 0, 0, 0.1);
}

.blog-detail-img {
  width: 100%;
  height: 100%;
  object-fit: cover;
  transition: transform 0.3s ease;
}

.blog-detail-img:hover {
  transform: scale(1.05);
}

.blog-detail-text {
  font-size: 18px;
  line-height: 1.8;
  color: #333;
}

.blog-detail-text p {
  margin-bottom: 20px;
}

/* Conclusion Styles */
.blog-detail-conclusion {
  margin: 60px auto;
  padding-top: 40px;
  border-top: 1px solid #eee;
  font-size: 18px;
  line-height: 1.8;
  color: #333;
}

/* Loading and Error States */
.blog-detail-loading,
.blog-detail-error {
  text-align: center;
  padding: 100px 20px;
  font-size: 20px;
  color: #666;
}

.blog-detail-error {
  color: #dc3545;
}

/* Responsive Design */
@media (max-width: 768px) {
  .blog-detail-content {
    padding: 20px;
  }

  .blog-detail-title {
    font-size: 36px;
  }

  .blog-detail-introduction {
    font-size: 18px;
  }

  .blog-detail-image-text-row {
    grid-template-columns: 1fr;
    gap: 20px;
  }

  .blog-detail-image {
    height: 300px;
  }

  .blog-detail-text {
    font-size: 16px;
  }
}

.blog-detail-container {
  position: relative;
  max-width: 1200px;
  margin: 0 auto;
  padding: 2rem;
}

.blog-detail-close-button {
  position: fixed;
  top: 2rem;
  right: 2rem;
  width: 48px;
  height: 48px;
  border-radius: 50%;
  background-color: white;
  border: 1px solid #e2e8f0;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;
  transition: all 0.2s ease;
  z-index: 100;
}

.blog-detail-close-button:hover {
  background-color: #f7fafc;
  transform: scale(1.05);
  box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
}

.blog-detail-close-button:active {
  transform: scale(0.95);
}

/* Responsive adjustments */
@media (max-width: 768px) {
  .blog-detail-close-button {
    top: 1rem;
    right: 1rem;
    width: 40px;
    height: 40px;
  }
}

/* Optional: Add a semi-transparent overlay when hovering over the close button */
.blog-detail-close-button::before {
  content: '';
  position: fixed;
  top: 0;
  right: 0;
  width: 100px;
  height: 100px;
  background: radial-gradient(circle, rgba(255,255,255,0.1) 0%, rgba(255,255,255,0) 70%);
  opacity: 0;
  transition: opacity 0.2s ease;
  pointer-events: none;
}

.blog-detail-close-button:hover::before {
  opacity: 1;
}
