.HomeWrapper {
  /* width: 100%; */
  height: 100vh;
  overflow-x: hidden;
}
.homeNavbar {
  height: 10%;
}
@media only screen and (max-width: 768px) {

  
}