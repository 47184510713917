.aiMsgWrapper {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  background: #ffffff;
  border-top: 3px solid #dfa114;
  border-radius: 4px;
  margin: 1rem;
}
.aiMsgHeading {
  font-weight: 700;
  padding: 1rem;
}
.aiMsgDescription {
  width: 60%;
  margin: auto;
  font-size: 1.6rem;
  text-align: center;
  padding: 1rem;
}
.aiMsgImgCon {
  padding: 1rem;
}
.aiMsgButton {
  margin: 1rem;
  width: 60%;
}
