.inputForm,
.inputForm select,
.inputForm input {
  color: black;
}

.resumePickerBtn {
  margin-left: 2rem;
}
.ProfiencyExam {
  display: flex;
  justify-content: space-between;
}

.examName {
  width: 70%;
}
.addedExam {
  margin-bottom: 2rem;
}
.addMoreBtnCon {
  padding: 1rem;
  border-radius: 8px;
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100%;
  font-weight: 600;
  background-color: #ecb344;
}
.addMoreBtn {
  height: 100%;
}

.regionModalCon {
  width: 100%;
  /* margin: 2rem; */
}

.uploadedFile {
  display: flex;
}

.removeBtn {
  background: none;
  border: none;
  width: 10%;
}

::placeholder {
  opacity: 1;
  color: black;
}
.stepIndicator {
  text-align: center;
  margin: 1rem 0;
  font-size: 1.2rem;
  color: #333;
}

.navigation-buttons {
  display: flex;
  justify-content: space-between;
  gap: 1rem;
  margin-top: 2rem;
  padding: 0 1rem;
}

.navigation-buttons button {
  min-width: 120px;
}
.steps-wrapper {
  display: flex;
  align-items: center;
  margin: 2rem 0;
}

.step-number {
  width: 32px;
  height: 32px;
  border-radius: 50%;
  border: 2px solid #010334;
  display: flex;
  align-items: center;
  justify-content: center;
  margin-right: 0.5rem;
}

.step-text {
  margin-right: 2rem;
  font-weight: 500;
}

.step-active {
  background-color: #010334;
  color: white;
}

.step-inactive {
  background-color: white;
  color: #010334;
}
.inputForm {
  width: 100%;
  max-width: 800px;
  margin: 0 auto;
}

.inputForm > * {
  margin-bottom: 1.5rem;
}

/* Mobile responsiveness */
@media (max-width: 768px) {
  .inputForm {
    padding: 0.5rem;
  }
  
  .navigation-buttons {
    flex-direction: column;
  }
  
  .navigation-buttons button {
    width: 100%;
  }
}
