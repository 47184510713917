.landingWrapper {
  min-height: 90vh;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}
.landingHeading1 {
  width: 70%;
  font-size: 6.4rem;
  font-weight: 700;
  text-align: center;
  padding: 2rem;
}
.landingHeading2 {
  font-weight: 300;
  font-size: 2.4rem;
  text-align: center;
  width: 37.5%;
  padding: 2rem;
}

.landingBtn {
  padding: 1rem;
  margin: 2rem;
}

.homeBtn{
  color:black;
}

@media only screen and (max-width: 768px) {
  .homeSection {
  }
  .landingHeading1 {
    font-size: 3.5rem;
    padding: 1rem;
    width: 80%;
  }
  .landingHeading2 {
    font-size: 2rem;
    width: 70%;
    margin-top: 3rem;
    padding: 1rem;
    margin-bottom: 3rem;
  }
  
  .landingBtn {
    padding: 0.5rem;
  }
  
}
