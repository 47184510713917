.latestOppWrapper {
  width: 100%;
  display: flex;
  justify-content: space-around;
  padding: 1rem;
}
.latestOppImgCon {
  width: 20%;
  display: flex;
  justify-content: center;
  align-items: center;
}
.latestOppImg {
  width: 60%;
}
.latestOppContainer {
  width: 60%;
  display: flex;
  justify-content: space-between;
}
.latestOppDayandTime {
  display: flex;
}
.latestOppFirmName {
  font-weight: 1.6rem;
  font-weight: 600;
  padding: 0.2rem;
}
.latestOppDetail {
  display: flex;
  justify-content: center;
  align-items: center;
  font-weight: 500;
  font-size: 1.4rem;
  padding: 0.2rem;
}
.latestOppBtn {
  display: flex;
  justify-content: center;
  align-items: center;
  font-size: 2.2rem;
  font-weight: 600;
}
