.brandImgWrapper {
  display: flex;
  justify-content: center;
  align-items: center;
  background: white;
  padding: 3rem;
  border-radius: 8px;
}

@media only screen and (max-width: 768px) {
  .brandImgWrapper {
    display: flex;
    justify-content: center;
    align-items: center;
    background: white;
    padding: 1rem;
    margin: 1rem;
    border-radius: 8px;
  }
}

