.dropdownSearch {
  width: 90%;
  margin: auto;
  padding: 0.5rem;
  border-radius: 0.5rem;
}

.singleItem {
  display: flex;
}

.dropdown-menu {
  max-height: 30vh;
  overflow-y: scroll;
  overflow-x: hidden;
  flex-direction: column;
}

.selectedItems {
  width: 90%;
  margin: auto;
  padding: 1rem;
}

.removeAll {
  color: red;
  text-decoration: underline;
}
.countryName {
  padding: 0.2rem;
  margin: 0.2rem;
  background-color: #0103341a;
}

.optionsDropdown{
  padding: 2rem;
}