.category {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  padding: 1rem
}
.categoryImg {
  padding: 1rem;
}
.categoryType {
  padding: 1rem;
  text-align: center;
  font-weight: 800;
}
.categoryInfo {
  padding: 1rem;
  text-align: center;
  color: #666;
}


@media screen and (max-width: 768px) {
  .categoryImg {
    padding: 1rem;
  }
  .categoryType {
    padding: 1rem;
    font-size: 2rem;
    text-align: center;
    font-weight: 800;
  }
  .categoryInfo {
    padding: 1rem;
    font-size: 1.5rem;
    text-align: center;
    color: #666;
  }
}
