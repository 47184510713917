.oppsDetailsWrapper {
  display: flex;
  flex-direction: column;
  /* justify-content: center; */
  /* align-items: center; */
  background: #ffffff;
  border-top: 3px solid #dfa114;
  border-radius: 4px;
  padding: 1.5rem;
}

.oppsDetailsHeading {
  font-weight: 700;
  font-size: 3rem;
  padding: 1.5rem;
}

.oppDetail {
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  padding: 1rem 1.5rem 1rem 1.5rem;
}
.oppDetailRow {
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  padding: 1rem 1.5rem 1rem 1.5rem;
}

.oppField {
  font-size: 2rem;
  font-weight: 800;
  padding: 0.5rem;
}
.fieldInfo {
  padding: 0.5rem;
}
