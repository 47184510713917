.side-navbar {
  display: flex;
  justify-content: space-between;
  position: sticky;
  top: 58px;
  background: #010334;
  color: #fff;
  /* height: 92.5vh; */
  height: 100vh;
}

.menu-list {
  list-style-type: none;
  margin: 0;
  color: #ffffff;
  opacity: 60%;
  font-size: 1.5rem;
  padding: 2rem;
  height:40%;
}

.menu-list li {
  padding: 1rem 2.5rem;
  margin: 0 1rem;
  border-radius: 10px;
  cursor: pointer;
  transition: all 0.2s ease-in-out;
}

.menu-list li:hover {
  opacity: 100%;
  background: linear-gradient(to right, #040883, rgba(0, 0, 0, 0));
}
