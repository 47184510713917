.dropdownWrapper {
  width: 99%;
  border: 1px black solid;
  opacity: 1;
  border-radius: 8px;
}

.dropdown-menu {
  flex-direction: column;
}

.dropdown-input {
  position: relative;
  z-index: 1;
  padding: 1rem;
  display: flex;
  align-items: center;
  justify-content: space-between;
  user-select: none;
  /* border: 1px solid #010334; */
  opacity: 1;
  /* background-color: rgb(255, 255, 255, 0.4) !important; */
}

/* Style for the label (placeholder) */
.dropdownLabel {
  position: absolute;
  z-index: 100;
  left: 1rem;
  top: 0;
  transform: translateY(-50%);
  transition: all 0.5s ease;
  font-size: 1.7rem;
  color: grey;
  padding: 0.1rem;
  background-color: white;
}

.dropdown-selected-item {
  opacity: 1;
}

.dropdown-tags {
  display: flex;
  flex-wrap: wrap;
  gap: 5px;
}

.dropdown-tag-item {
  background-color: #ddd;
  padding: 2px 4px;
  border-radius: 2px;
  display: flex;
  align-items: center;
}

.dropdown-tag-close {
  display: flex;
  align-items: center;
}

.dropdown-item {
  align-self: flex-start;
  width: 100%;
  padding: 5px;
  cursor: pointer;
  display: flex;
  /* justify-content: center; */
}

.dropdown-item:hover {
  background-color: #e4e4e4;
}
