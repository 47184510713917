.latestOppsWrapper {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  background: #ffffff;
  border-top: 3px solid #dfa114;
  border-radius: 4px;
  margin: 1rem 1rem 3rem 1rem;
}
.latestOppsHeading {
  font-size: 2rem;
  font-weight: 700;
  padding: 1rem;
}
.viewAllBtn {
  border: none;
  background: none;
  color: red;
}
.latestOpps {
  width: 100%;
  padding: 1rem;
}
