.fieldsWrapper {
  display: flex;
  flex-wrap: wrap;
  width: 100%;
}

.fieldWrapper {
  padding: 0.5rem;
  margin: 0.5rem;
  border: 2px solid;
}
