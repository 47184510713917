.dashboardWrapper {
  display: flex;
  flex-direction: column;
  height: 100vh;
  width: 100%;
  overflow-x: hidden;
}
.dashboardHeader {
  position: sticky;
  top: 0;
  z-index: 5;
}
.dashboardInfo {
  display: flex;
  width: 100%;
  height: 100%;
  overflow: auto;
}
.dashboardSidebar {
  width: 20%;
  height: 100%;
  position: sticky;
  top: 0;
}
.dashboardOppurtunities {
  width: 80%;
  background-color: #f5f7fa;
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
}
.dashboardOpp {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 95%;
  height:100%;
  /* margin: auto; */
}

@media screen and (max-width: 800px) {
  .dashboardSidebar {
    display: none;
    width: 20%;
    height: 100%;
    position: sticky;
    top: 0;
  }
  .dashboardOpp {
    width: 95%;
    margin: auto;
  }
  .dashboardOppurtunities {
    width: 100%;
  }
}