@import url("./variables.css");

.hero-card,
.card {
  border-radius: 10px;
  padding: 1rem;
  margin-bottom: 1rem;
  box-shadow: var(--card-shadow);
  margin: 0 1rem;
}

.hero-banner {
  width: 100%;
  height: 16rem;
}

.hero-banner img {
  width: 100%;
  height: 100%;
  object-fit: cover;
}

.hero-avatar {
  width: var(--avatar-width);
  height: auto;
  position: absolute;
  margin-top: calc(-1 * var(--avatar-width) / 2);
  margin-left: calc(var(--avatar-width) / 4);
}

.hero-avatar img {
  width: 100%;
  height: 100%;
  object-fit: cover;
  border-radius: 50%;
  border: 5px solid #fff;
  box-shadow: var(--avatar-shadow);
}

.hero-details {
  margin-left: calc(1.5 * var(--avatar-width));
  padding: var(--spacing) 0;
}

.hero-title {
  font-size: var(--head-font-size);
  font-weight: bold;
  margin-bottom: calc(var(--spacing) / 2);
}

.hero-subtitle {
  font-size: calc(var(--head-font-size) / 1.5);
  margin-bottom: calc(var(--spacing) / 5);
  opacity: var(--subtle-opacity);
}

.card {
  padding: calc(var(--spacing) * 2);
  margin: 2rem 1rem;
}

.card-title {
  font-size: calc(var(--head-font-size) / 1.2);
  font-weight: bold;
  margin-bottom: calc(var(--spacing));
}

.card-subtitle {
  font-size: calc(var(--head-font-size) / 1.5);
  font-weight: bold;
  margin: calc(var(--spacing) / 5) 0;
}

.card-details {
  font-size: 1.8rem;
  opacity: var(--subtle-opacity);
}

p.description {
  font-size: calc(var(--head-font-size) / 2);
  font-weight: bold;
  margin-bottom: calc(var(--spacing) / 2);
}

.pill {
  border: 2px solid lightgrey;
  border-radius: 2rem;
  padding: calc(var(--spacing) / 2) var(--spacing);
  margin-right: calc(var(--spacing));
  margin-bottom: calc(var(--spacing));
  font-size: calc(var(--head-font-size) / 2);
  color: var(--primary-color);
  font-weight: bold;
  letter-spacing: 0.05rem;
}

.card-image {
  max-height: 10rem;
  width: auto;
  margin-right: calc(var(--spacing));
}

.red {
  color: red;
}

.yellow {
  color: #ecb344;
}
.green {
  color: green;
}

.light-divider {
  opacity: calc(var(--subtle-opacity) / 2);
  margin: calc(var(--spacing) / 1.5) 0;
}
