@media screen and (max-width: 768px) {
  /* .hero-avatar {
    position: absolute;
    top: 450px; 
    left: 0;
  } */
  .hero-card {
    flex: 0 0 90%; /* Adjust the width of the hero card */
    margin: 1rem 0;
    padding: 1rem;
  }
  .hero-card {
    display: flex;
    flex-direction: column; /* Stack items vertically */
    align-items: center; /* Center items horizontally within .hero-card */
    overflow: hidden;
    /* Add other styles as needed */
  }
}

@media screen and (max-width: 280px) {
  .hero-avatar {
    position: absolute;
    top: 450px; /* Adjust the value as needed */
    left: 0;
  }
  .hero-card {
    flex: 0 0 90%; /* Adjust the width of the hero card */
    margin: 0.5rem 0;
    padding: 1rem;
  }
  .hero-card {
    display: flex;
    flex-direction: column; /* Stack items vertically */
    align-items: center; /* Center items horizontally within .hero-card */
    overflow: hidden;
    /* Add other styles as needed */
  }
}
