.chatbot-modal {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.5);
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 1000;
  align-items: flex-end; /* Align at the bottom */
  justify-content: flex-end; /* Align at the right */
}

.chatbot-container {
  width: 400px;
  height: 500px;
  border-radius: 10px;
  background-color: white;
  box-shadow: 0 2px 10px rgba(0, 0, 0, 0.1);
  display: flex;
  flex-direction: column;
  position: relative;
  margin-right: 40px;
  margin-bottom: 40px;
}

.chatbot-header {
  background-color: #dfa114;
  color: black;
  padding: 10px;
  border-top-left-radius: 10px;
  border-top-right-radius: 10px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  font-weight: 800;
  font-size: 2rem;
}

.close-btn {
  background: none;
  border: none;
  font-size: 20px;
  cursor: pointer;
}

.chatbot-messages {
  flex: 1;
  padding: 10px;
  overflow-y: auto;
  display: flex;
  flex-direction: column;
}

.message {
  margin: 5px 0;
  padding: 10px;
  border-radius: 5px;
  max-width: 80%;
}

.message.user {
  align-self: flex-end;
  background-color: #2b3093;
  color: white;
}

.message.bot {
  align-self: flex-start;
  background-color: #f1f1f1;
}

.chatbot-input-container {
  width: 95%;
  display: flex;
  padding: 10px;
  border-bottom-left-radius: 10px;
  border-bottom-right-radius: 10px;
}

.chatbot-input-container input {
  width: 80%;
  flex: 1;
  padding: 10px;
  border: 1px solid #ccc;
  border-radius: 5px;
  margin-right: 10px;
}

.chatbot-input-container button {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 20%;
  padding: 10px 15px;
  background-color: #dfa114;
  border: none;
  border-radius: 5px;
  cursor: pointer;
  color: black;
}

@media screen and (max-width: 768px) {
  .chatbot-container {
    width: 300px;
    height: 400px;
    border-radius: 10px;
    background-color: white;
    box-shadow: 0 2px 10px rgba(0, 0, 0, 0.1);
    display: flex;
    flex-direction: column;
    position: relative;
    margin-right: 50px;
    margin-bottom: 50px;
  }
}
