.messageWrapper {
  height: 100vh;
  display: flex;
  flex-direction: column;
  background: #010334;
  color: #ffffff;
  font-family: "Rogan";
}

.titleCompany {
  display: flex;
  justify-content: center;
  text-align: center;
  height: 50vh;
  padding: 15rem 0 0 0;
  cursor: pointer;
}

.opguruLogoImgBanner {
  height: 15%;
}
.titleCompany:hover {
  cursor: hand;
}

.messageCompany {
  display: flex;
  flex-direction: column;
  height: 50vh;
  width: 80%;
  margin: auto;
  font-size: 5.6rem;
  justify-content: center;
  align-items: center;
}

.changeCol {
  color: #dfa114;
}

.font4 {
  font-size: 4rem;
}

.font5-6 {
  font-size: 5.6rem;
}
