.roleWrapper {
  display: flex;
  flex-direction: row;
  width: 100%;
}

.messageRoleWrapper {
  width: 50%;
}

.Roles {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  width: 50%;
}

.width58 {
  width: 58% !important;
}

@media only screen and (max-width: 450px) {
  .messageRoleWrapper {
    display: none;
  }
  .Roles {
    width: 100%;
    min-height: 100%;
  }
}
