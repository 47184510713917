.resetPassWrapper {
  display: flex;
}

.resetFormWrapper {
  font-family: "Rogan";
  width: 51%;
  display: flex;
  flex-direction: column;
  height: 100vh;
  justify-content: center;
  align-items: center;
}

.midWrapperReset {
  display: flex;
  flex-direction: column;
  justify-content: space-evenly;
  align-items: center;
  /* height: 30%; */
  width: 50%;
}

#resetForm {
  width: 100%;
}

@media only screen and (max-width: 425px) {
  .resetFormWrapper {
    width: 80%;
    margin: auto;
  }
  .midWrapperReset {
    width: 100%;
  }
}
