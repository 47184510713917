.forgotPassWrapper {
  display: flex;
  width: 100%;
}

.forgotPassForm {
  font-family: "Rogan";
  width: 51%;
  display: flex;
  flex-direction: column;
  height: 100vh;
  justify-content: space-evenly;
  align-items: center;
}

.midWrapForgotPass {
  display: flex;
  flex-direction: column;
  justify-content: space-evenly;
  align-items: center;
  height: 80%;
  width: 50%;
}

.pageName {
  display: flex;
  justify-content: center;
  color: #010334;
  font-size: 4rem;
  font-weight: 700;
}

.phonenumWrap {
  width: 100%;
}

#forgotPassForm {
  width: 100%;
}

.otpForm {
  width: 100%;
}

.pad1 {
  padding: 1rem;
}

.dontWorryMsg {
  display: flex;
  text-align: center;
  justify-content: center;
  font-size: 1.8rem;
  width: 100%;
  padding: 1rem;
}

.userIDForgot {
  width: 100%;
}

.userIDForgotBtn {
  width: 100%;
}

@media only screen and (max-width: 425px) {
  .forgotPassForm {
    width: 80%;
    margin: auto;
  }
  .midWrapForgotPass {
    width: 100%;
  }
  .pageName {
    font-size: 3.2rem;
  }
}
