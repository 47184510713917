body.active-modal {
  overflow-y: hidden;
}

/* .regionFinalWrapper {
  margin: 2rem;
} */

.btn-modal {
  width: 100%;
  display: block;
  font-size: 18px;
  border: 1px black solid;
  background-color: #ffffff;
  border-radius: 8px;
  color: black;
}

.regionModalHeading {
  display: flex;
  justify-content: center;
  align-items: center;
  text-align: center;
  font-size: 3.2rem;
  font-weight: 700;
}

.regionModal,
.overlay {
  width: 100vw;
  height: 100vh;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  position: fixed;
  background-color: rgba(0, 0, 0, 0.5);
  opacity: 1.6 !important;
}

.modal-content-regions {
  display: block;
  position: fixed;
  overflow-y: scroll;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  line-height: 1.4;
  background: #ffffff;
  border-radius: 3px;
  width: 40%;
  padding: 4rem;
  height: 60%;
}

.close-modal {
  position: absolute;
  top: 10px;
  right: 10px;
  padding: 5px 7px;
  background: none;
  border: none;
  transform: scale(1.5);
}

.regionTagWrapper {
  color: black;
  position: relative;
  display: flex;
  align-items: center;
  padding: 1rem;
}

.regionLabel {
  position: absolute;
  z-index: 0;
  left: 1rem;
  top: 0;
  transform: translateY(-50%);
  transition: all 0.5s ease;
  font-size: 1.7rem;
  color: grey;
  padding: 0.1rem;
  background-color: white;
}

.regionTag {
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 2px 4px;
  margin: 0.4rem;
  background-color: #ddd;
  border-radius: 2px;
}

.regionDelete {
  padding-top: 0.2rem;
}

@media only screen and (max-width: 768px) {
  .modal-content-regions {
    width: 80%;
    padding: 2rem;
    overflow-x: hidden;
  }
  .regionModalHeading {
    font-size: 2.5rem;
    font-weight: 700;
  }
  .close-modal {
    position: absolute;
    top: 5px;
    right: 5px;
    padding: 2px 3px;
    background: none;
    border: none;
    /* transform: scale(1.5); */
  }
}
