.oppWrapper {
  box-sizing: border-box;
  background: #ffffff;
  border-radius: 4px;
  margin: 1rem 1rem 1.5rem 1rem;
  padding: 2rem;
}
.oppInfo {
  display: flex;
}
.oppType {
  display: flex;
  justify-content: space-around;
}
.oppFirmImgCon {
  display: flex;
  justify-content: center;
  align-items: center;
  background: #ffffff;
  border: 1px solid #e0e0e0;
  border-radius: 4px;
  padding: 1rem;
  margin: 1rem;
}
.oppHeading {
  font-size: 2.5rem;
  font-weight: 700;
  padding: 1rem;
}
.oppDate,
.oppDateActive {
  padding: 1rem;
}
.oppDateActive {
  color: #00b81e;
  background: rgba(0, 199, 32, 0.1);
  border: 1px solid rgba(0, 199, 32, 0.3);
  border-radius: 2px;
}
.oppFirm {
  font-size: 2.2rem;
  font-weight: 500;
  padding: 1rem;
}
.oppDetailSpan {
  font-size: 2.2rem;
  font-weight: 700;
}
.oppFeatures {
  display: flex;
  justify-content: space-between;
  width: 50%;
  padding: 1rem;
}
.oppFeature {
  border: 1px solid #010334;
  border-radius: 4px;
  padding: 0.5rem 1rem;
}
.oppDescription {
  padding: 1rem;
}
.oppStatus {
  display: flex;
  justify-content: space-between;
  padding-top: 2rem;
}
.oppStipend {
  display: flex;
  padding: 1rem;
  width: 30%;
}
.oppCurrPeople {
  display: flex;
  padding: 1rem;
}
.applyButton {
  margin: 1rem;
}

.oppSingleDetailLeft {
  display: flex;
  width: 25%;
  justify-content: space-around;
  align-items: center;
  padding: 1rem;
  border: 2px solid;
  /* border-radius: 50px; */
}
.oppIconWrapper {
  width: 20%;
  padding: 0.5rem;
}
.oppIcon {
  width: 80%;
}
.oppInformation {
  width: 60%;
  align-items: center;
  font-weight: 700;
  font-size: 2.2rem;
  display: flex;
  justify-content: center;
}
.oppSingleDetailCenter {
  display: flex;
  width: 25%;
  justify-content: center;
  align-items: center;
  padding: 0.5rem;
  border: 2px solid;
}
.oppIconWrapperPeople {
  width: 10%;
  padding: 0.5rem;
}
.oppIconPeople {
  width: 80%;
}
.oppInformationPeople {
  width: 90%;
  font-weight: 700;
  font-size: 2.2rem;
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 0.5rem;
}
.oppSingleDetailRight {
  display: flex;
  width: 25%;
  justify-content: center;
  align-items: center;
  padding: 1rem;
}

@media only screen and (max-width: 768px) {
  .oppSingleDetailLeft {
    width: 40%;
    margin: 0.5rem;
  }
  .oppInformation {
    width: 60%;
    font-size: 1.5rem;
    padding: 0.5rem;
  }
  .oppIconWrapper {
    width: 30%;
    padding: 0.5rem;
  }
  .oppIcon {
    width: 100%;
  }
  .oppSingleDetailCenter {
    margin: 0.5rem;
  }
  .oppIconWrapperPeople {
    display: none;
  }
  .oppInformationPeople {
    width: 100%;
    font-weight: 700;
    font-size: 1.5rem;
    display: flex;
    justify-content: center;
    align-items: center;
  }
  .oppSingleDetailRight {
    display: flex;
    width: 40%;
    justify-content: center;
    align-items: center;
    padding: 1rem;
  }
}
