.selectroleWrapper {
  display: flex;
  flex-direction: column;
  justify-content: center;
  width: 80%;
}

.allOptions {
  width: 90%;
  margin: auto;
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  row-gap: 6vh;
  column-gap: 3%;
  justify-items: center;
  align-items: center;
  margin: 2rem;
}

.optionsHeading {
  display: flex;
  font-size: 4rem;
  font-weight: 700;
  justify-content: center;
  padding: 1rem;
  text-align: center;
}

.optionsSubHeading {
  display: flex;
  font-size: 2rem;
  justify-content: center;
  padding: 1rem;
  text-align: center;
}

.box {
  padding: 4rem;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}
.box:hover {
  border: 1.82018px solid rgba(223, 161, 20, 0.7);
  border-radius: 5.5987px;
  cursor: pointer;
}

.optionImgCon {
  display: flex;
  justify-content: center;
  align-items: center;
}

.optionName {
  font-size: 1.8rem;
  font-weight: 700;
  text-align: center;
}

.active {
  border: 1.82018px solid rgba(223, 161, 20, 0.7);
  border-radius: 5.5987px;
}

@media only screen and (max-width: 600px) {
  .selectroleWrapper {
    min-height: 100%;
    display: flex;
    flex-direction: column;
    justify-content: center;
    width: 80%;
  }
  .Roles {
    margin-top: 4rem;
  }
  .allOptions {
    margin: auto;
    width: 100%;
    margin-bottom: 2rem;
  }
  .optionsHeading {
    display: flex;
    font-size: 2.8rem;
    font-weight: 700;
    justify-content: center;
    padding-bottom: 1rem;
    text-align: center;
  }
  .optionsSubHeading {
    display: flex;
    font-size: 1.6rem;
    justify-content: center;
    padding-bottom: 1rem;
    text-align: center;
  }
  .optionName {
    font-size: 1.4rem;
    font-weight: 700;
    text-align: center;
  }
  .optionsImage {
    width: 90%;
  }
}
