.yellowBtn {
  display: flex;
  justify-content: center;
  font-size: 2rem;
  font-weight: 700;
  background-color: #dfa114;
  width: 100%;
  border: none;
  border-radius: 6px;
  padding: 1rem;
  cursor: pointer;
  color: black;
}

.yellowBtn:hover {
  cursor: hand;
}

.whiteBtn {
  display: flex;
  justify-content: center;
  font-size: 2rem;
  font-weight: 700;
  background-color: white;
  border: 1px solid #010334;
  width: 100%;
  border-radius: 6px;
  padding: 1rem;
}

@media screen and (max-width: 768px) {
  .yellowBtn {
    font-size: 1.5rem;
  }
}
