.opportunityWrapper {
  display: flex;
  flex-direction: column;
  min-height: 100vh;
  background-color: #0d0b1f;
}

.opportunityHeader {
  width: 100%;
}

.opportunityBottom {
  display: flex;
  flex: 1;
}

.opportunitySidebar {
  width: 40%;
  background: #0d0b1f;
}

.opportunityRight {
  display: flex;
  flex-direction: column;
  padding: 2rem;
  justify-content: space-between;
  height: 100%;
  min-width: 80%;
}

.tab-navigation {
  margin-bottom: 1.5rem;
}

.content-area {
  background: white;
  border-radius: 0.5rem;
  padding: 1.5rem;
}

.opportunity-item {
  padding-bottom: 1.5rem;
  border-bottom: 1px solid #e5e7eb;
}

.opportunity-item:last-child {
  padding-bottom: 0;
  border-bottom: none;
}

/* Task styles */
.task-item {
  display: flex;
  align-items: center;
  gap: 1rem;
  padding: 1rem;
  background: #f9fafb;
  border-radius: 0.5rem;
  margin-bottom: 0.75rem;
}

.task-item.completed {
  background: #f3f4f6;
}

/* Custom checkbox styling */
input[type="checkbox"] {
  border: 2px solid #dfa114;
  border-radius: 4px;
  cursor: pointer;
}

input[type="checkbox"]:checked {
  background-color: #dfa114;
}
