.inputForm,
.inputForm select,
.inputForm input
{
  color: black !important; /* Light grey text color */
}

.tenthPercentage,
.tenthPercentage input {
  color: #808080; /* Light grey text color */
}
