.benifitWrapper {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  min-height: 100vh;
}

.homeHeading1 {
  width: 85%;
  font-size: 5.6rem;
  font-weight: 900;
  text-align: center;
  padding: 3rem;
  margin-bottom: 4rem;
}
.homeHeadingSpan {
  width: 85%;
  font-size: 5.6rem;
  font-weight: 900;
  text-align: center;
  padding-left: 1rem;
  padding-right: 1rem;
  color: #dfa114;
}
.benifitHeading2 {
  font-weight: 300;
  font-size: 2.4rem;
  text-align: center;
  width: 60%;
  padding: 2rem;
}

.benefitsInfo {
  width: 90%;
  display: flex;
  justify-content: space-between;
}

.benifitInfo {
  width: 40%;
  display: flex;
  flex-direction: column;
  justify-content: center;
}

.benifit {
  display: flex;
  flex-direction: row;
  align-items: center;
  margin: 2rem auto;
}

.benifitImgCon {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 50%;
}

.benifitText {
  margin: 2rem;
}

@media screen and (max-width: 768px) {
  .homeHeading1 {
    font-size: 2.6rem;
    margin-bottom: 1rem;
  }
  .homeHeadingSpan {
    width: 85%;
    font-size: 2.5rem;
    font-weight: 700;
    text-align: center;
    padding: 0.5rem;
  }
  .leadImg {
    width: 90%;
    margin: auto;
  }
  .benefitsInfo {
    width: 90%;
    /* justify-content: center; */
    flex-direction: column;
    /* margin: auto; */
  }
  .benifitInfo {
    width: 100%;
  }
  .benifit {
    width: 100%;
    display: flex;
    flex-direction: row;
    align-items: center;
    margin: 2rem 0;
  }
  .benifitImgCon {
    width: 100%;
  }
  .benifitText {
    font-size: 1.8rem;
    font-weight: 700;
  }
  .benifitIcon {
    width: 10%;
    padding: 1rem;
  }
}
