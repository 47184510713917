.personalDetailsWrapper {
  overflow: auto;
}

.studentFormWrapper {
  padding: 5rem;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  -webkit-box-shadow: 0 0 6px rgb(0, 0, 0);
  box-shadow: 0 0 6px rgb(0, 0, 0);
  border-radius: 8px;
  background: #ffffff;
  width: 43.75%;
  margin: auto;
  height: 80%;
  margin-bottom: 10rem;
  margin-top: 5rem;
  overflow: auto;
}

.nameWrapper {
  display: flex;
  width: 100%;
}

.inputForm {
  opacity: 0.9;
  border-radius: 8px;
  border-radius: 8px;
  margin: 1rem;
  font-size: 2rem;
  /* margin: 2rem; */
}

.firstName,
.lastName {
  width: 50%;
}

.formHeading {
  font-size: 3.2rem;
  font-weight: 700;
}

.personalDetailsBtns {
  width: 80%;
  display: flex;
  justify-content: space-evenly;
}

.Btn1 {
  width: 40% !important;
}
@media only screen and (max-width: 600px) {
  .studentFormWrapper {
    width: 92%;
    padding: 2rem 1.3rem;
    overflow-x: hidden;
  }
  .formHeading,
  .formMessage {
    text-align: center;
  }
}
