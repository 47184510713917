.mentorCardWrapper{
    display: flex;
    justify-content: space-around;
    margin: 2rem;
    border: Mixed solid #888888;
    box-shadow: 0px 4px 10px 0px #0000001A;
    border-radius: 1rem;
}
.mentorCardImgWrapper{
    width:20%;
    padding: 1rem;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    
}
.mentorCardImg{
    width:100%;
    border-radius: 50%;
}
.mentorCardDetails{
    width:70%;
    padding: 1rem;
}
.mentorCardConnect{
    display: flex;
    justify-content: center;
    align-items: center;
    padding: 1rem;
}
.mentorDetail{
    width:100%;
    display: flex;
    flex-direction: row;
    justify-content: flex-start;     
}
.mentorDetailRow{
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
}
.mentorField{
    font-size: 2rem;
    font-weight: 800;
    padding: 0.5rem;
}

.yellowBtnConnect{
    display: flex;
  justify-content: center;
  font-size: 1.8rem;
  font-weight: 700;
  background-color: #dfa114;
  width: 100%;
  border: none;
  border-radius: 6px;
  padding: 0.6rem 0.8rem;
  color: black;
}

@media screen and (max-width: 800px) {
    .mentorCardWrapper{
        flex-direction: column;
        margin:0.5rem;
    }
    .mentorCardImgWrapper{
        width:100%;
        flex-direction: row;
        justify-content: space-between;
    }
    .mentorCardImg{
        width:40%;
        border-radius: 50%;
        align-self: flex-start;
    }
    .mentorCardConnect{
        display: flex;
        justify-content: center;
        align-items: center;
        padding: 1rem;
        margin-right: 2rem;
    }
    .mentorCardDetails{
        width:90%;
        padding: 1rem;
    }
    .mentorDetail{
        width:100%;
        display: flex;
        flex-direction: column;
        justify-content: flex-start;     
    }
  }
  .popup-overlay {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-color: rgba(0, 0, 0, 0.5);
    display: flex;
    justify-content: center;
    align-items: center;
    z-index: 1000;
    animation: fadeIn 0.3s ease-out;
  }
  
  .success-popup {
    background: white;
    padding: 2rem;
    border-radius: 12px;
    box-shadow: 0 4px 20px rgba(0, 0, 0, 0.15);
    text-align: center;
    max-width: 400px;
    animation: slideIn 0.5s ease-out;
  }
  
  .success-icon {
    width: 60px;
    height: 60px;
    background: #4CAF50;
    border-radius: 50%;
    display: flex;
    align-items: center;
    justify-content: center;
    margin: 0 auto 1rem;
    color: white;
    font-size: 30px;
    animation: scaleIn 0.5s ease-out;
  }
  
  .success-popup h3 {
    color: #333;
    margin: 0 0 1rem;
    font-size: 1.5rem;
  }
  
  .success-popup p {
    color: #666;
    margin: 0;
    line-height: 1.5;
  }
  
  @keyframes fadeIn {
    from {
      opacity: 0;
    }
    to {
      opacity: 1;
    }
  }
  
  @keyframes slideIn {
    from {
      transform: translateY(-20px);
      opacity: 0;
    }
    to {
      transform: translateY(0);
      opacity: 1;
    }
  }
  
  @keyframes scaleIn {
    from {
      transform: scale(0);
    }
    to {
      transform: scale(1);
    }
  }
  
  /* Add hover effect to the connect button */
  .yellowBtnConnect {
    transition: all 0.3s ease;
  }
  
  .yellowBtnConnect:hover:not(:disabled) {
    transform: translateY(-2px);
    box-shadow: 0 4px 12px rgba(223, 161, 20, 0.2);
  }
  
  .yellowBtnConnect:disabled {
    opacity: 0.7;
    cursor: not-allowed;
  }