.teamWrapper {
  min-height: 80vh;
  display: flex;
  flex-direction: column;
  justify-content: space-around;
  align-items: center;
  margin-bottom: 7rem;
}
.teamHeading1 {
  width: 70%;
  font-size: 6.4rem;
  font-weight: 700;
  text-align: center;
  padding: 1rem;
  margin-top: 2rem;
}

/* .teamHeading2 {
  width: 70%;
  font-size: 2.4rem;
  font-weight: 400;
  text-align: center;
  padding: 2rem;
  margin-bottom: 2rem;
} */

.foundersSection {
  padding-bottom: 4rem;
  height: 100%;
}

.foundersHeading {
  width: 100%;
  display: flex;
  justify-content: center;
  font-size: 3rem;
  font-weight: 800;
  padding: 2rem;
  text-decoration: underline;
}

.foundingMembers {
  display: flex;
  justify-content: space-around;
}

.eachFounder {
  width: 45%;
}

.teamMembers {
  display: grid;
  grid-template-columns: repeat(4, 1fr);
  row-gap: 7vh;
  width: 95%;
  margin: auto;
  column-gap: 3vh;
}

.teamHeading {
  width: 100%;
  display: flex;
  justify-content: center;
  font-size: 3rem;
  font-weight: 800;
  padding-top: 3rem;
  padding-bottom: 4rem;
  text-decoration: underline;
}

@media screen and (max-width: 768px) {
  .teamHeading1 {
    width: 70%;
    font-size: 3rem;
    font-weight: 700;
    text-align: center;
    padding: 2rem;
  }
  .teamHeading2 {
    width: 70%;
    font-size: 2rem;
    font-weight: 400;
    text-align: center;
    padding: 2rem;
    margin-bottom: 2rem;
  }
  .teamMembers {
    display: grid;
    grid-template-columns: repeat(1, 1fr);
    row-gap: 5vh;
    width: 90%;
    margin: auto;
    column-gap: 3vh;
  }
  .foundingMembers {
    display: flex;
    flex-direction: column;
    justify-content: space-around;
  }
  .eachFounder {
    width: 100%;
  }
  .eachMember {
    width: 100%;
  }
}
